import {React, Fragment, useRef, useState} from "react";
import { Dialog, Transition } from '@headlessui/react';
export const TaskLogtable = () => {
    const [openoMde, setopenMode] = useState(false);
    const cancelButtonRef = useRef(null);
    return(
       <div>
            <div className="logtable mt-5 overflow-x-auto">
                <table className="w-full text-left border border-slate-300">
                    <thead>
                        <tr>
                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold  text-slate-800 text-xs border border-slate-300">User/IP Address</th>
                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold  text-slate-800 text-xs border border-slate-300">Activity</th>
                            <th className="whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold  text-slate-800 text-xs border border-slate-300">Browser/OS</th>
                            <th className="text-center whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold  text-slate-800 text-xs border border-slate-300">Date</th>
                            <th className="text-center whitespace-nowrap bg-slate-200 px-4 py-3 font-semibold  text-slate-800 text-xs border border-slate-300">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3 border border-slate-200">
                              <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-slate-800">
                                            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                                        </svg>
                                    <span className="pl-1 block text-sm">SuperAdmin1</span>
                               </div>
                               <div className="flex items-center mt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-sm text-sky-500">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z" />
                                    </svg>
                                    <span className="block text-sm text-sky-600 ml-1">115.97.85.161</span>
                              </div>
                        </td>
                        <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3 border border-slate-200">
                            <span className="block text-sm">Jobtask <span className="font-bold">pack124</span>has been <span className="font-bold">Updated</span>
                            </span>
                        </td>
                        <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3 border border-slate-200">
                            <div className="flex items-center text-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                 <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                                </svg>
                                <span className="ml-1">Google Chrome</span>
                            </div>
                            <div className="flex items-center text-sm mt-1">
                               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                                </svg>
                                <span className="ml-1">windows</span>
                            </div>
                         
                        </td>
                        <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3 border border-slate-200">
                               <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-slate-800">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                    </svg>
                                    <span className="pl-1 font-medium text-slate-700 text-sm">01/30/2023 </span>
                                </div>
                        </td>
                        <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3">
                            <div className="flex justify-center">
                                <button type="button" className="bg-sky-600 hover:bg-sky-500 px-4 py-3 rounded-sm" onClick={() => setopenMode(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-white">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </button>
                             </div>
                        </td>
                        </tr>
                    </tbody>
                </table>
           </div>
            {/* popup*/}
            <Transition.Root show={openoMde} as={Fragment}>
                     <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setopenMode}>
                                        <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                        >
                                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                        </Transition.Child>

                                        <div className="fixed inset-0 z-10 overflow-y-auto">
                                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                            <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                            <Dialog.Panel className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-xl lg:max-w-xl">
                                                <div>
                                                    
                                                    <div className="flex justify-between items-center bg-gray-200 py-2 px-3">
                                                        <Dialog.Title as="h3" className="text-lg font-bold">
                                                         Changes Made
                                                        </Dialog.Title>
                                                        <span onClick={() => setopenMode(false)} ref={cancelButtonRef}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div className="py-8 px-4">
                                                       <div className="table-wrp block max-h-96 overflow-y-auto">
                                                        <table className="w-full text-left border border-slate-300">
                                                                <thead>
                                                                   
                                                                    <tr>
                                                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-2 font-semibold text-slate-800 text-sm border border-slate-300">
                                                                          Key
                                                                        </th>
                                                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-2 font-semibold text-slate-800 text-sm border border-slate-300">
                                                                            Previous Value
                                                                        </th>
                                                                        <th className="whitespace-nowrap bg-slate-200 px-4 py-2 font-semibold text-slate-800 text-sm border border-slate-300">
                                                                        Changed Value
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="whitespace-nowrap font-medium text-slate-700 text-sm px-4 py-1 border border-slate-200 py-3">xxx</td>
                                                                        <td className="whitespace-nowrap font-medium text-slate-700 text-sm px-4 py-1 border border-slate-200 py-3">xxxx</td>
                                                                        <td className="whitespace-nowrap font-medium text-slate-700 text-sm px-4 py-1 border border-slate-200 py-3">xxxx</td>
                                                                    </tr>
                                                                   
                                                                 </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                        </div>
                    </Dialog>
            </Transition.Root>
                            {/* End  popup*/}
      </div>
       
    )

}