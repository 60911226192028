import {Router } from './routes/AppRoutes';

function App() {
 
  return (
   <>
   <Router />
   </>
 );
}

export default App;