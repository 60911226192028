import { Link} from 'react-router-dom';
import { useLocation } from "react-router-dom";
import swal from "sweetalert";
export const Header = () => {
   const location=useLocation()
   const isTaskRendering = location.pathname==="/task"
   const user = JSON.parse(localStorage.getItem('user'));
   const handleLogout = () => {
   
   // swal("success","Successfully! Logged out", "success");
    //timer: 2000;
    swal("Success","Successfully! Logged out", "success", {
        buttons: false,
        timer: 2000
      }).then(value => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("user");
        window.location.href = "/";
      });
    
   
    
  };
    return(
        <nav className="bg-headerColor shadow-sm">
           <div className="mx-auto px-4 py-1 flex justify-between">
               <div className="text-slate-800 flex items-center text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                    </svg>
                    <span className="text-base font-semibold ">{user.name}</span> 
               </div>
               <div className="flex">

               {/*!isTaskRendering &&
               <Link to="/task" className="inline-flex items-center border border-transparent px-1 py-2 text-base font-medium leading-4 text-white focus:outline-none focus:ring-0">
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                   <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
               </svg>
               <span>Back</span>
               </Link>
                */}
               {!isTaskRendering &&
                        <Link to="/task" className="inline-flex items-center border border-transparent px-1 py-2 text-base font-medium leading-4 text-white focus:outline-none focus:ring-0">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                            </svg>
                            <span>Tasks</span>
                        </Link>
                }
                    
                    <Link onClick={handleLogout} className="inline-flex items-center border border-transparent ml-4 px-1 py-2 text-base font-medium leading-4 text-white focus:outline-none focus:ring-0">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                        </svg>
                        <span className="ml-1" >Sign Out</span>
                    </Link>
               </div>
           </div>
        </nav>
    )
}