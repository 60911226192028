import {useState, useEffect} from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import swal from "sweetalert";

export const Notes = () =>{
    const location = useLocation();
    console.log(location.pathname);
    const mystr=location.pathname;
    const  myParam = mystr.split("/");
    const operator_id = myParam[5];
    const tasktype = myParam[2];
    const workstation_id = myParam[4];
    const task_id = myParam[3];
    const temptask_id = myParam[6];
    const [errorMas, setErrorMas] = useState(false);

    const [addNote, setaddNote] = useState(false);
    const [notes, setNotes] = useState([]);
    const [noteVal, setNoteVal] = useState("");
    const [noteId, setNoteId] = useState(null);
    const handelAddnote = (id, rec_notes) => {
        console.log('id', id);
        if(id == null){
            setNoteVal('');
        } else {
            setNoteVal(rec_notes);
        } 
        setNoteId(id);
        setaddNote(true); 
    };
    const handelSavenote = () => {
        console.log('jdd', noteVal);
        const token = "Bearer " + localStorage.getItem('accessToken');
        //const headers = { 'Authorization': token };
        if(noteVal == ''){
            setErrorMas(true);
            console.log("Please Enter Notes");
            setTimeout(() => {
                setErrorMas(false);
              }, 3000);
            return;
        }

        const notesData = {
            id : noteId,
            operator_notes :  noteVal,
            task_id : task_id,
            temptask_id : temptask_id
        }
        var noteurl = 'operatornotesadd';
        if(noteId !=null) {
             noteurl = 'operatornotesupdate';
        }
        console.log('notes', notesData);
        
        axios.post(`${"production/"+noteurl}`, notesData).then((response) => {
            console.log('data', response);
            if(response.data.success == true){
                
                const notesrec = response.data.data;
                //this.state.items[1].name = 'updated field name'
                //setNotes(notes => notes.concat(notesrec));
                getNotes();
                swal("Success", response.data.message, "success", {
                    buttons: false,
                    timer: 2000
                })
                setNoteVal('');
            } else {
                swal("Failed", 'Something went wrong', "error");
            }
        }).catch(function(err){ console.log(err);swal("Failed", 'Something went wrong', "error"); });
        //setaddNote(true); 
    };
    const handelDeletenote = (e, id)=> {
        e.preventDefault();
        swal({
            title: "Are you sure?",
            text: "You want delete this notes!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                setNoteVal('');
                setNoteId(null);
                setaddNote(false); 
                var notesids = { 'ids' : [id]}
                
                axios.post(`${"production/operatornotesdelete"}`, notesids).then((response) => {
                    console.log('data', response);
                    if(response.data.success == true){
                        setNotes((current) =>
                            current.filter((note) => note.id !== id)
                        );
                        swal("Success", response.data.message, "success", {
                            buttons: false,
                            timer: 2000
                        })
                    } else {
                        swal("Failed", 'Something went wrong', "error");
                    }
                }).catch(function(err){ console.log(err);swal("Failed", 'Something went wrong', "error"); });
            } 
          });
        
    };
    const additionalUrl =  "production/operatornotes/"+ task_id + "/" + temptask_id;
    const token = "Bearer " + localStorage.getItem('accessToken');
    //const headers = { 'Authorization': token };
    const handleMessageChange = event => {
        // 👇️ access textarea value
        setErrorMas(false);
        setNoteVal(event.target.value);
        console.log(event.target.value);
      };

    const getNotes = () => {
        axios.get(`${additionalUrl}`).then((response) => {
            console.log('data', response);
            if(response.data.data){
                const notesrec = response.data.data;
                setNotes(notesrec);
            }
        });
    }
    useEffect(() => {
        //Get Notes
        getNotes();
        
    }, []);         

    return(
        <div className="mt-2">
            {addNote && 
               <div>
                <div className="flex justify-between items-center">
                    <h2 className="text-base font-bold">Add Notes</h2>
                    <button type="button" className="rounded-sm bg-green-600 hover:bg-green-500 text-white text-[16px] font-medium flex items-center ml-1 p-5 py-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                        </svg>
                        <span className="ml-1" onClick={handelSavenote}>Save Note</span>
                    </button>
                </div>
                <div className="flex flex-col mt-2">
                    <textarea value={noteVal}
        onChange={handleMessageChange} className=" rounded-none p-1 border border-gray-300 text-gray-900 text-xs font-medium w-full h-32"></textarea>
        {errorMas &&
                               <div className="flex font-semibold text-sm mt-2 flex text-red-600">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                     <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                  </svg>
                                  <span className="pl-1">Please Enter Notes</span>
                              </div>
                          }
                </div>
             </div>             
            }
                 <div className="flex justify-between items-center mt-2">
                    <h2 className="text-base font-bold">Notes:</h2>
                    <button type="button" onClick={() => handelAddnote(null)} className="rounded-sm bg-sky-600 hover:bg-sky-500 text-white text-[16px] font-medium flex items-center p-5 py-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                        </svg>
                        <span>Add Notes</span>
                    </button>
                </div>
                <div className="notetable mt-2 overflow-x-auto w-full">
                    <table className="w-full text-left border border-slate-300">
                          <colgroup>
                            <col width="40%" />
                            <col width="20%" />
                            <col width="10%" />
                            <col width="30%" />
                          </colgroup>
                        <thead>
                        <tr>
                            <th className="bg-slate-200 px-4 py-3.5 font-semibold text-slate-800 text-xs border border-slate-300"> Notes </th>
                            <th className="bg-slate-200 px-4 py-3.5 font-semibold text-slate-800 text-xs border border-slate-300 whitespace-nowrap"> User Name </th>
                            <th className="bg-slate-200 px-4 py-3.5 font-semibold text-slate-800 text-xs border border-slate-300"> Date </th>
                            <th className="text-center bg-slate-200 px-4 py-3.5 font-semibold text-slate-800 text-xs border border-slate-300"> Action </th>
                        </tr>
                        </thead>
                        <tbody>
                        
                        {notes.map((note,index) => (
                        <tr key={index}>
                            <td className="font-medium text-slate-700 text-xs px-4 py-4 border border-slate-200"> {note.operator_notes} </td>
                            <td className="font-medium text-slate-700 text-xs px-4 py-4 border border-slate-200 whitespace-nowrap"> 
                               <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-slate-800">
                                            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                                        </svg>
                                    <span className="pl-1">{note.operator_name} </span>
                               </div>
                            </td>
                            <td className="font-medium text-slate-700 text-xs px-4 py-4 border border-slate-200">
                               <div className="flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-slate-800">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                    </svg>
                                    <span className="pl-1"> {note.createdat}  </span>
                                </div>
                            </td>
                            <td className="font-medium text-slate-700 text-xs px-4 py-4 border border-slate-200">
                                <div className="flex justify-center">
                                        <button type="button" onClick={() => handelAddnote(note.id, note.operator_notes)} className="bg-orange-500 hover:bg-orange-400 px-4 py-3 rounded-sm">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                       </button>
                                        
                                        <button type="button" onClick={e => handelDeletenote(e, note.id)} className="ml-4 bg-red-600 hover:bg-red-500 px-4 py-3 rounded-sm">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                                               <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </button>
                                </div>
                           
                            </td>
                        </tr>
                        ))}
                        </tbody>
                    </table>
                </div>          
        </div>
    )
}