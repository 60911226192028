import {React, useState} from "react";
import logo from '../../assets/images/logo.png';
import backdelet from '../../assets/images/backdelete.png';
import swal from "sweetalert";
import "./login.css" 
export const Login = () =>{
  const [number, setpad] = useState('');
  const [warning, setwarning] = useState(false);
  const [errorMas, seterrorMas] = useState(false);
 // const [operatorcode, setOpcode] = useState();
  
  const handelCheck  = e => { 
   
      let newNumber = number + e.target.id
    
       if(e.target.id === '0'){
          newNumber = number + "0";
          
       }
      if(newNumber.length <= 4){
        setpad(newNumber);
        seterrorMas(false);
      }
      else{
        setwarning(true);
        return false;
      }
  };

  const handdeleteFull = () =>{
    const blankNum = "";
    setpad(blankNum);
    setwarning(false);
  };

  const handdeleteNum = () =>{
      let newNum = number;
      let lastDig = newNum.substring(0, newNum.length - 1);
      setpad(lastDig);
      setwarning(false);
  };

  async function loginUser(credentials) {

    //console.log(credentials
//const operatorURL = "https://ntapi.trotterportal.com/api/operatorlogin";
const operatorURL = "https://trotter.odtestlink.com/api/operatorlogin";

    return fetch(operatorURL, {

      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(credentials)
    }).then(data => data.json());
  }

  const handleSubmit = async e => {
    e.preventDefault();
    const typeNumber = number + e.target.id;
    if(typeNumber === '' || typeNumber.length <= 3){
      seterrorMas(true);
     }
     else{
      seterrorMas(false);
     // console.log(number);
      const response = await loginUser({"operatorcode":number});
//console.log(Object.keys(response.data).length);
     // return;
      if (response.success!='false' && Object.keys(response.data).length > 0) {
       // console.log(Object.keys(data));
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000
        }).then(value => {
          localStorage.setItem("accessToken", response.data.token);
          localStorage.setItem("user", JSON.stringify(response.data));
          window.location.href = "task";
        });
      } else {
        swal("Failed", response.message, "error");
       // console.log(response);
      }
  
     }
  }

    return(
        <div className="login h-full">
               <div className="flex min-h-full flex-col justify-center sm:px-6 lg:px-12">
                    <div className="sm:mx-auto sm:w-full sm:max-w-xl">
                      <div className="bg-white py-8 lg:py-4 px-4 shadow sm:rounded-2xl sm:px-10">
                      <div className="sm:mx-auto sm:w-full sm:max-w-md">
                      <img
                        className="mx-auto h-12 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                      <h2 className="mt-4 text-center text-2xl font-semibold tracking-tight text-slate-700 ">Welcome to Operator Portal</h2>
                      <h3 className="mt-2 text-center text-xl font-semibold text-gray-600 subpixel-antialiased">
                        Operator Code:
                      </h3>
                    </div>
                    <form onSubmit = {handleSubmit}>
                
                      <div className="mb-4 mt-4 flex justify-center relative flex-col items-center">
                        <div className="rounded-md invisible border border-gray-300 font-bold text-2xl text-gray-900 text-center tracking-[10px] p-3 w-36 h-14 absolute flex justify-center items-center">{number}</div>
                          <input
                            id="operatorcode:"
                            name="operatorcode"
                            type="number"
                            onChange={handelCheck}
                            readOnly={true}
                            value={number}
                            autoComplete="number"
                            className="block w-64 appearance-none rounded-md border border-gray-300 font-bold text-3xl text-gray-900 text-center tracking-[10px] p-3 423placeholder-gray-400 shadow-sm focus:border-gray-300 focus:outline-none"
                          />
                          {warning && 
                            <div className="flex justify-center items-center font-semibold text-sm mt-2 flex text-orange-500">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>
                                <span className="pl-1 text-base">Code should not be above four digit number</span>
                            </div>
                          }
                          {errorMas &&
                               <div className="flex justify-center items-center font-semibold text-sm mt-2 flex text-red-600">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                     <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                  </svg>
                                  <span className="pl-1">Code should be four digit number</span>
                              </div>
                          }
                      </div>
                      <div className="flex justify-center">
                          <div className="w-2/3">
                            <div className="flex flex-wrap justify-center">
                              <button type="button" id={1} onClick={handelCheck} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold text-3xl w-20 h-16 rounded mb-4">1</button>
                              <button type="button" id={2} onClick={handelCheck} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold text-3xl w-20 h-16 rounded mb-4 mx-4">2</button>
                              <button type="button" id={3} onClick={handelCheck} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold text-3xl w-20 h-16 rounded mb-4">3</button>
                            </div>
                            <div className="flex flex-wrap justify-center">
                              <button type="button" id={4} onClick={handelCheck} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold text-3xl w-20 h-16 rounded mb-4">4</button>
                              <button type="button" id={5} onClick={handelCheck} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold text-3xl w-20 h-16 rounded mb-4 mx-4">5</button>
                              <button type="button" id={6} onClick={handelCheck} className="bg-gray-300 hover:bg-gray-400 text-gray-800gray-800 fo font-semibold text-3xl w-20 h-16 rounded mb-4">6</button>
                            </div>

                            <div className="flex flex-wrap justify-center">
                              <button type="button" id={7} onClick={handelCheck} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold text-3xl w-20 h-16 rounded mb-4">7</button>
                              <button type="button" id={8} onClick={handelCheck} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold text-3xl w-20 h-16 rounded mb-4 mx-4">8</button>
                              <button type="button" id={9} onClick={handelCheck} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold text-3xl w-20 h-16 rounded mb-4">9</button>
                            </div>
                            <div className="flex flex-wrap justify-center">
                              <button type="button" onClick={handdeleteFull} className="bg-red-500 hover:bg-red-600 text-white font-semibold text-3xl w-20 h-16rounded flex justify-center items-center mb-4 rounded">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                              </button>
                              <button type="button" id={0} onClick={handelCheck} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold text-3xl w-20 h-16 rounded mb-4 mx-4">0</button>
                              <button type="button" onClick={handdeleteNum} className="bg-orange-500 hover:bg-orange-600 text-white font-semibold text-3xl w-20 h-16 rounded mb-4 flex justify-center items-center">
                                <img src={backdelet} alt="icon" />
                              </button>
                            </div>
                            <div className="flex flex-wrap justify-center"> 
                              <button type="submit" className="bg-green-500 hover:bg-green-600 text-white font-semibold text-3xl w-20 h-16 rounded">
                                  <span className="flex justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                                    </svg>
                                  </span>
                                </button>
                            </div>
                          </div>
                      </div>
                     
                  </form>
                 </div>
              </div>
            </div>
        </div>
     )
}