import {React, Fragment, useRef, useState, useEffect} from "react";
import { Dialog, Transition } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import swal from "sweetalert";
import { RevolvingDot } from 'react-loader-spinner';

export const OtherTable = ({onChangeNumber}) => {
	 
	const location = useLocation();
    
    const mystr=location.pathname;
    const  myParam = mystr.split("/");
    //const operatorid = myParam[5];
    const tasktype = myParam[2];
    const workstation_id = myParam[4];
    const task_id = myParam[3];
    const temptask_id = myParam[6];
    const tdetail=location.state;
    const workstation_name = tdetail?tdetail.work_name:'';
    const loss_percentage = tdetail?tdetail.loss_per:'';
    const job_id = tdetail?tdetail.job_id:'';
    const module_id = tdetail?tdetail.module_id:'';
    
     
    const userInfo = JSON.parse(localStorage.getItem('user'));
    console.log('localStorage', userInfo); 
    const [operatorid, setOperatorid] = useState(myParam[5]);
    const [loading, setLoading] = useState(false);
    console.log(myParam);
    const [records, setRecords] = useState([]);
    const [taskStatus, setTaskStatus] = useState("");

    const [startBtnVisible, setStartBtnVisible] = useState(true);
    const [mkBtnVisible, setMKBtnVisible] = useState(false);
    const [partialstartBtnVisible, setPartialStartBtnVisible] = useState(false);
    const [taskInstruction, setTaskInstruction] = useState('');
    const [openItemDetail, setOpenItemDetail] = useState(false);
    const token = "Bearer " + localStorage.getItem('accessToken');
    //const headers = { 'Authorization': token };
    const [taskOperator, setTaskOperator] = useState(true);
    
    const gettaskdetail = () => {
        setLoading(true);
        
        const additionalUrl =  "production/jobnew/"+ tasktype +"/taskview/" + task_id + "/" + workstation_id + "/" + operatorid;
        console.log(additionalUrl);
        axios.get(`${additionalUrl}`).then((response) => {
            console.log('data', response.data.data);
            setLoading(false);
            if(response.data.data){
                const rec = response.data.data;
                console.log('rec', rec);
                setRecords(rec);
                if(rec.task_status_id == 2) {
                    setStartBtnVisible(false);
                }
                if(rec.task_status_id >= 4) {
                    setTaskOperator(false);
                }
                
                if(rec.partialreadystatus == 1 && rec.task_status_id > 3) {
                    setPartialStartBtnVisible(true);
                }
                setTaskStatus(rec.task_status_name);
                if(rec.task_status_id == 3) {
                    setMKBtnVisible(true); // mark as complete btn
                }
                if(rec.task_status_id >= 3) {
                    setStartBtnVisible(false);
                }
            }
            
        });

    }

    useEffect(() => {
        //Get Task Details
        gettaskdetail();
    }, []);         

    console.log('hiiiiii');
     // Important Note
    //const [imNote, setimNote] = useState(false);
    const handelImportantNote = () => setOpenItemDetail(true);
    //const handelcloseInfo = () =>  setimNote(false); 
   // Task Instruction Popup
    const [openInstruction, setopenInstruction] = useState(false);
    const handelInstruction = e => {
        axios.get(`${"master/jobinstruction/"+records.jobinstruction_id}`).then((response) => {
            console.log('data', response);
            if(response.data.data){
                setTaskInstruction(response.data.data);
                 console.log(response.data.data);
                 
            }
        });
        setopenInstruction(true);
    }
    const cancelButtonRef = useRef(null);
    // For Number Pad
     
    const assignOperator = (val) => {
        
        if(val=='assign'){
            const userInfo = JSON.parse(localStorage.getItem('user'));
            var operator_user_id = userInfo.id;
        } else {
            var operator_user_id = null;
        }
         
        var readystatusData = { 
            task_id: task_id, 
            operator_user_id: operator_user_id, 
            tasktemp_id: temptask_id,
            type: tasktype
        }
        axios.post(`${"production/changeoperator"}`, readystatusData).then((response) => {
            console.log('data', response);
            if(response.data.success == true){
                console.log('data11', response);
                setOperatorid(operator_user_id);
                swal("Success", response.data.message, "success", {
                    buttons: false,
                    timer: 2000
                  })
            }
        }).catch(function(err){ console.log(err);swal("Failed", 'Something went wrong', "error"); });
    }
     

    const changeTask = (val) => {
        console.log(val);
        console.log('hh');
        console.log('operatorid',operatorid);
        if(operatorid == 'null' || operatorid == null){
            swal("Failed", 'Operator Required', "error");
            return
        }
         
        const statusData = {
                status: val,
                rejection_note:  null,
                type: tasktype,
            }
        axios.put(`${"production/jobnew/"+ job_id +"/updatetaskstatus/"+ task_id + "/" +tasktype}`, statusData).then((response) => {
            console.log('data', response);
            if(response.data.success == true){
                gettaskdetail();
                if(val==3){
                    setStartBtnVisible(false);
                    setMKBtnVisible(true);
                } else if(val==4){

                    setMKBtnVisible(false);
                }
                swal("Success", response.data.message, "success", {
                    buttons: false,
                    timer: 2000
                  })
            } else {
                swal("Failed", 'Something went wrong', "error");
            }
        }).catch(function(err){ console.log(err);swal("Failed", 'Something went wrong', "error"); });
    }

    const downloadAttachment = (name) => {
        axios.get(`${"jobinstructionfiledownload/" + name}`).then((response) => {
            console.log('data', response.config.url);
            if(response){
                console.log('insideresponse');
                window.open(response.config.url);
            }
        });
    } 
 

   // End For Number Pad
    return(
        <div>
            {loading ? (
                <div className="loderDiv">  
                <RevolvingDot
                height="60"
                width="60"
                radius="28"
                color="#179BDF"
                ariaLabel="watch-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
                />
                </div>  
                ): ("")
            } 

                 <div className="flex justify-between items-center border-b-[1px] border-slate-150 pb-2">
                     <div className="flex items-center">
                       <h2 className="text-base font-bold">{records.jobid}-Ad hoc</h2>
                       {taskOperator && 
                           <span>
                                    {(operatorid == null || operatorid == "null") ? (    
                                    <button type="button" onClick={() => assignOperator('assign')} className="rounded-sm text-white text-base font-medium flex items-center bg-green-600 ml-3 py-3 px-3">
                                        <span>Assign to Me</span> 
                                    </button>
                                    ) : (
                                    <button type="button" onClick={() => assignOperator('unassign')} className="rounded-sm text-white text-base font-medium flex items-center bg-red-600 ml-3 py-3 px-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                        <span>Un-Assign</span> 
                                    </button>
                                     )}
                            </span>
                        }  
                        </div>
                       <div className="flex">
                       {startBtnVisible && <button type="button" onClick={() => changeTask(3)} className="rounded-sm bg-green-600 hover:bg-green-500 text-white font-semibold text-[16px] px-4 py-5 flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                </svg>
                                <span className="ml-1">Start Task</span> 
                            </button>
                        }
                        {mkBtnVisible && <button type="button" onClick={() => changeTask(4)} className="rounded-sm ml-2 bg-green-600 hover:bg-green-500 text-white font-semibold text-[16px] px-4 py-5 flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span className="ml-1">Mark All Completed</span> 
                            </button>
                        }
                        </div>
                   </div>
                   
                   <div>
                        <div className="flex items-center pt-3">
                           <div className="w-9/12">
                              <h2 className="text-base font-bold">{records.taskdescription} - {records.completiondate}</h2>
                           </div>
                           {records.tasknotes && <div className="flex items-center justify-end w-3/12 relative">
                           <button type="button" className="text-sky-600 underline text-xs font-semibold flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>
                                  <span className="ml-1 text-base lg:text-xl" onClick={handelImportantNote}>Important Notes</span> 
                             </button>
                           </div>}
                        </div>


                        <div className="flex flex-wrap pt-3 pb-8">
                            <div className="w-9/12 pr-2 space-y-5">
                               
                                <div className="flex items-center py-2">
                                    <h3><span className="text-base font-bold">Workstation:</span><span className="text-base font-medium"> {workstation_name} <span className="text-red-600">(1% loss)</span></span></h3>
                                </div>
                              
                            </div>

                           
                           
                            <div className="w-3/12 flex items-center flex-col justify-center">
                                <div className="border border-blue-600">
                                    
                                    <span className="text-sm font-semibold text-white bg-blue-600 text-center block px-4 py-1.5">{taskStatus}</span>
                                </div>  
                                 
                            </div>

                            <div className="flex flex-col mt-6">
                                    <div className="flex items-center">
                                        <h3><span className="text-base font-bold">Task Instruction:</span></h3>
                                        <button type="button" onClick={handelInstruction} className="rounded-sm text-white text-xs font-semibold flex items-center bg-sky-600 ml-3 px-6 py-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            </svg>
                                        </button>
                                    </div>
                                     

                                     
                            {/* Task Instruction image popup*/}
                            <Transition.Root show={openInstruction} as={Fragment}>
                                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setopenInstruction}>
                                        <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                        >
                                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                        </Transition.Child>

                                        <div className="fixed inset-0 z-10 overflow-y-auto">
                                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                            <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                            <Dialog.Panel className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-2xl">
                                                <div>
                                                    
                                                    <div className="flex justify-between items-center bg-gray-200 py-3 px-3">
                                                        <Dialog.Title as="h3" className="text-lg font-bold">
                                                         Task Instruction:
                                                        </Dialog.Title>
                                                        <span onClick={() => setopenInstruction(false)} ref={cancelButtonRef} className="cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div className="py-8 px-4">
                                                        <div className="pb-4 text-lg">{taskInstruction.description?taskInstruction.description.replace(/(<([^>]+)>)/ig, '') : '' }
                                                        </div>
                                                       <div className="table-wrp block max-h-96 overflow-y-auto">
                                                       <table className="w-full text-left border border-slate-300">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="2" className="whitespace-nowrap px-4 py-3 font-semibold  text-slate-800 text-sm border border-slate-300">
                                                                            <div className="flex">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                                                                </svg>
                                                                                <span>Attachments</span> 
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="whitespace-nowrap w-[80%] bg-slate-200  px-4 py-2 font-semibold   text-slate-800 text-xs border border-slate-300">
                                                                            Name
                                                                        </th>
                                                                        <th className="whitespace-nowrap w-[20%] bg-slate-200  px-4 py-2 font-semibold  text-slate-800 text-xs border border-slate-300">
                                                                            Download
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {taskInstruction.attachments && taskInstruction.attachments.map((instruction,index) => (
                                                                    <tr>
                                                                        <td className="whitespace-nowrap font-medium text-slate-700 text-base px-4 py-1 border border-slate-200 py-5">{instruction.orig_name}</td>
                                                                        <td className="whitespace-nowrap font-medium text-slate-700 text-xs border border-slate-200 text-center"> 
                                                                            <button onClick={() => downloadAttachment(instruction.name)} type="button" className="flex justify-center items-center h-24 w-full">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-orange-400">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                                                </svg>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>
                                                                ))}     
                                                                 </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                        </div>
                                    </Dialog>
                              </Transition.Root>
                            {/* End Task Instruction image popup*/}
                            {/* Important notes popup*/}
                            <Transition.Root show={openItemDetail} as={Fragment}>
                                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenItemDetail}>
                                        <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                        >
                                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                        </Transition.Child>

                                        <div className="fixed inset-0 z-10 overflow-y-auto">
                                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                            <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                            <Dialog.Panel className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-2xl">
                                                <div>
                                                    
                                                    <div className="flex justify-between items-center bg-gray-200 py-3 px-3">
                                                        <Dialog.Title as="h3" className="text-lg font-bold">
                                                         Important Notes:
                                                        </Dialog.Title>
                                                        <span onClick={() => setOpenItemDetail(false)} ref={cancelButtonRef} className="cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div className="py-8 px-4">
                                                        <div className="pb-4 text-lg">{records.tasknotes}
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                
                                            </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                        </div>
                                    </Dialog>
                              </Transition.Root>
                            {/* End Item Detail popup*/}
                            </div>
                           
                        </div>

                   </div>
                 
        </div>
    )
}