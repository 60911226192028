import {React, Fragment, useRef, useState, useEffect} from "react";
import { Dialog, Transition } from '@headlessui/react';
import {UpdatePallets} from './UpdatePallets';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import swal from "sweetalert";
import { RevolvingDot } from 'react-loader-spinner';

// For Number Pad
const packaginginitialValues = {
    packagingTotalWeight: '',
    packaginglossdata: '',
    packagingweightProduced: '',
    TotalWeight: '',
    lossdata: '',
    weightProduced: '',
    netWt:''
  };
  const taskDetail = {
    workstation_name: '',
    loss_percentage: '',
    cust_name: '',
    jobid: '',
    //taskTime: '',
    //weightProduced: ''
};
export const PackagingTable = ({onChangeNumber}) => {
    // UpdatePallets
    const [enteredData, setEnteredData] = useState(taskDetail);
	const location = useLocation();
    console.log(location);
    const mystr=location.pathname;
    const  myParam = mystr.split("/");
    const operator_id = myParam[5];
    const tasktype = myParam[2];
    const workstation_id = myParam[4];
    const task_id = myParam[3];
    const temptask_id = myParam[6];
    const tdetail=location.state;
    const workstation_name = tdetail.work_name;
    const loss_percentage = tdetail.loss_per;
    const job_id = tdetail.job_id;
    const module_id = tdetail.module_id;
    const [isoverride, setIsoverride] = useState(0);
    
    // setEnteredData({
    //     ...enteredData,
    //     ["workstation_name"]: location.state.work_name,
       
    //  });

    const userInfo = JSON.parse(localStorage.getItem('user'));
    console.log('localStorage', userInfo);
    console.log('localStorage', userInfo.id);
    const [loading, setLoading] = useState(false);
    const [operatorid, setOperatorid] = useState(myParam[5]);
    console.log(myParam);
    
    const [records, setRecords] = useState([]);
    const [customerDetailVar, setCustomerDetailVar] = useState("");
    const [taskTime, setTaskTime] = useState("");
    const [taskStatus, setTaskStatus] = useState("");
    const [taskPackage, setTaskPackage] = useState([]);

    const [startBtnVisible, setStartBtnVisible] = useState(true);
    const [mkBtnVisible, setMKBtnVisible] = useState(false);
    const [partialstartBtnVisible, setPartialStartBtnVisible] = useState(false);
    const [totalWeightApplied, setTotalWeightApplied] = useState(0);
    const [totalPercentage, setTotalPercentage] = useState(0);
    const [totalWeightProduced, setTotalWeightProduced] = useState(0);
    const [process_id, setProcessId] = useState(0);
    const [packItemName, setPackItemName] = useState('');
    const [taskInstruction, setTaskInstruction] = useState('');
    const [itemDetail, setItemDetail] = useState([]);
    const [openItemDetail, setOpenItemDetail] = useState(false);
    const [taskOperator, setTaskOperator] = useState(true);
    const [defaultWeightProduced, setDefaultWeightProduced] = useState(0);
    
   // const [tdetail, setTaskOperator] = useState(false);
   const [palletData, setPalletData] = useState({});
    const [error, setError] = useState(null);
    const token = "Bearer " + localStorage.getItem('accessToken');
    //const token = "Bearer 6497|X14KyqpyoQ32bsqWrc77KaTabh3ndbqGO8vOPkoz";
    //const headers = { 'Authorization': token };

    const gettaskdetail = () => {
        const additionalUrl =  "production/jobnew/"+ tasktype +"/taskview/" + task_id + "/" + workstation_id + "/" + operatorid;
        console.log(additionalUrl);
        axios.get(`${additionalUrl}`).then((response) => {
            console.log('data', response.data.data);
            if(response.data.data){
                const rec = response.data.data;
                console.log('rec', rec);
                setRecords(rec);

                //start Override Alert of dependancy 
                var packtaskstatus = rec.packaging[0].task_status_name;
                if(rec.process_dependency_id != '' && packtaskstatus == "Open") {
                //if(rec.process_dependency_id != '') {
                    axios.get(`${"production/jobnew/overrideweightalert/"+rec.process_id}`+"/package").then((response) => {
                    console.log('data', response);
                    if(response.data.data.heatprocess){
                        //setDependencyBom(response.data.data.heatprocess);
                        var overrideitems = [];
                        //compare depandency weight produced with heat process weight in
                        var overridelots = "";
                        rec.packaging.forEach((processchildelement) => {
                            console.log('dependencyBom', response.data.data.heatprocess);
                            if (response.data.data.heatprocess.length>0) {
                                var metalweight = parseFloat(processchildelement['weight_applied']).toFixed(2);
                                response.data.data.heatprocess.forEach((overrideProcess, overrideindex) => {
                                    console.log('isideloop');
                                    if(processchildelement.lot_id == overrideProcess.lotid && metalweight > overrideProcess.weight_produced) {
                                        var item = {
                                            id: processchildelement.id,
                                            overwriteweight: overrideProcess.weight_produced,
                                        };
                                        overridelots+= overridelots + "\nLot Number : "+ processchildelement.lot_number + " => Weight Produced : "+ overrideProcess.weight_produced
                                        overrideitems.push(item);
                                    }
                                });
                            }
                        });
                        console.log('overrideitems', overrideitems);
                       
                      //return;
                      if (overrideitems.length > 0) {
                            if(isoverride != 1){
                                var sentence = "Lot wt. produced at the parent task is less than the applied wt. in the current task" + overridelots;
                                swal({
                                    title: "Do you want to override the net wt.?",
                                    text: sentence,
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                .then((willContinue) => {
                                    console.log('w',willContinue);
                                    if (willContinue) {
                                    var overrideData = {
                                            "process_type":"package",
                                            "overrideitem":overrideitems
                                        }
                                        saveOverrideData(overrideData);
                                    }  
                                });
                            } else {
                                setIsoverride(1);
                            } 
                        }
                    }
                });
                 
                }
                //End of override alert

                var array = [];
                rec.packaging.forEach((element) => {
                    element['weight_applied'] =  parseFloat(element['weight_applied']).toFixed(2);
 
                    array.push(element);
                });
                setTaskPackage(array);
                //setTaskPackage(rec.packaging); 
                
               // const values = [...taskPackage];
                console.log('taskPackage', taskPackage);

                setProcessId(rec.process_id);
                //Total Weight Applied
                var totalweightapplied = rec.packaging.reduce((total, obj) => parseFloat(obj.weight_applied) + parseFloat(total),0);
                setTotalWeightApplied(totalweightapplied);
                //Total Percentage
                var totPerc = rec.packaging[0]? rec.packaging[0].loss_qty: loss_percentage;
                var percFloat = parseFloat(totPerc).toFixed(2);
                setTotalPercentage(percFloat);

                var weight_produced = totalweightapplied - (Number(totPerc) * Number(totalweightapplied)) / 100;
                weight_produced = (weight_produced);
                if(rec.packaging[0] && rec.packaging[0].totalweightproduced > 0){
                    var totWeightProd = rec.packaging[0].totalweightproduced;
                } else {
                    var totWeightProd = weight_produced.toFixed(2);
                }
               
                setTotalWeightProduced(parseFloat(totWeightProd).toFixed(2));
                setDefaultWeightProduced(parseFloat(totWeightProd).toFixed(2));
                if(rec.joborderitems && rec.joborderitems.length > 0){
                    setPackItemName(rec.joborderitems[0]['item_name']);
                }
               /* if(rec.joborderitems && rec.joborderitems.length > 0){

                    var customerDetailVar = '';
                    
                    var customerDetails = rec.joborderitems.forEach((element) => {
                      setPackItemName(element.item_name);
                      if(customerDetailVar != ''){
                        customerDetailVar += " | ";
                      }
                      customerDetailVar += element.customer + " - " + element.order_id + ' - ' + element.item_name ;
                      //customerName = element.customer;
                    });
                    setCustomerDetailVar(customerDetailVar);
                    console.log('customerDetailVar', customerDetailVar);
                }*/

                if(rec.packaging[0]) {
                    const packaging = rec.packaging[0];
                   // setTaskTime("00:00");
                    setTaskStatus(packaging.task_status_name);
                    if(packaging.task_status_id == 2) {
                        setStartBtnVisible(false); // waiting for prior task
                    }

                    if(packaging.task_status_id >= 4) {
                        setTaskOperator(false);
                        setTaskTime(packaging.totaltime);
                    }

                    if(rec.partialreadystatus == 1 && packaging.task_status_id >2) {
                        setPartialStartBtnVisible(true);
                    }
                     
                    if(packaging.task_status_id == 3) {
                        setMKBtnVisible(true); // mark as complete btn
                    }
                    if(packaging.task_status_id >= 3) {
                        
                        setStartBtnVisible(false);
                         
                      }

                }
                //Task Time 
                
            }
            // var palletParam = {
            //     job_id : job_id,
            //     process_id : process_id,
            // }

            // setPalletData(palletParam);
            
        });
    }

    useEffect(() => {
        //Get Task Details
        gettaskdetail();
    }, []);         

    console.log('hiiiiii');
      // UpdatePallets
    const [updatePallets, setupdatePallets] = useState(false);
    const handelPallets = () => {
        console.log('handle', 'ss');
        var palletParam = {
            job_id : job_id,
            process_id : process_id,
            module_id : module_id,
        }
        setPalletData(palletParam);
        setupdatePallets(true); 
    }


     // Important Note
    //const [imNote, setimNote] = useState(false);
    const handelImportantNote = () => setOpenItemDetail(true);
    //const handelcloseInfo = () =>  setimNote(false); 
   // Task Instruction Popup
    const [openInstruction, setopenInstruction] = useState(false);
    const handelInstruction = e => {
        axios.get(`${"master/jobinstruction/"+records.jobinstruction_id}`).then((response) => {
            console.log('data', response);
            if(response.data.data){
                setTaskInstruction(response.data.data);
                 console.log(response.data.data);
                 
            }
        });
        setopenInstruction(true);
    }
    // const handelItemDetail  = (val, val1) => {
    //     axios.get(`${"production/jobnew/joborderitemdetail/"+val}`).then((response) => {
    //     //axios.get(`${"master/jobinstruction/"+records.jobinstruction_id}`).then((response) => {
    //         console.log('data', response);
    //         if(response.data.data){
    //             response.data.data[0].itemname = val1
    //             setItemDetail(response.data.data[0]);
                
    //              console.log(response.data.data[0]);
                 
    //         }
    //     });
    //     setOpenItemDetail(true);
    // }
    const cancelButtonRef = useRef(null);
    // For Number Pad
    
    const onchangeprocessHandel = (e) => {
        if (!/^[0-9]*\.?[0-9]*$/.test(e.key)) 
        { 
            e.preventDefault();
        }
        console.log(e.target.name, e.target.value);

        const { value } = e.target;
        console.log('value', value);
        // check if value includes a decimal point
        if (value.match(/\./g)) {
            const [, decimal] = value.split('.');

            // restrict value to only 2 decimal places
            if (decimal?.length > 2) {
               var newVal = parseFloat(value).toFixed(2);
                console.log('decim', decimal.length);
                console.log('newVal', newVal);
            } else {
                var newVal = value;
            }
        } else {
            var newVal = value;
        }
       
        
        if(e.target.name == "packagingTotalWeight") {
            
            console.log(e.target.name);
            setTotalWeightApplied(newVal);
            var percentage = totalPercentage;
            var totalweight = newVal;
        } else if(e.target.name == "packaginglossdata") {
            setTotalPercentage(newVal);
            console.log('ss', e.target.name);
            var percentage = newVal;
            var totalweight = totalWeightApplied;
        } else if(e.target.name == "packagingweightProduced") {
            //setDefaultWeightProduced(e.target.defaultValue);
            setTotalWeightProduced(newVal);
            var diff = Number(totalWeightApplied) - Number(newVal);
            console.log('diff', diff);
            var percentage =  (100 * diff) / totalWeightApplied;
            setTotalPercentage(percentage.toFixed(2));

            var weight_produced = newVal;
            console.log('ssssss', e.target.name);
        }
        if(e.target.name != "packagingweightProduced") {
            console.log('totalweight', totalweight);
            console.log('percentage', percentage);
            var weight_produced = totalweight - (Number(percentage) * Number(totalweight)) / 100;
            weight_produced = (weight_produced.toFixed(2));
            console.log('weight_produced', weight_produced);
            setTotalWeightProduced(weight_produced);
        }

        taskPackage[0].totalweightapplied = totalweight;
        taskPackage[0].totalweightproduced = weight_produced;
        taskPackage[0].loss_qty = percentage;
        
        
    //  setEnteredData({
    //      ...enteredData,
    //      [e.target.name]: e.target.value,
        
    //   });
   };

   const handleSubmit = e => {
       
    if(operatorid == 'null' || operatorid == null){
        swal("Failed", 'Operator Required', "error");
        return
    }
       console.log('hidid', e);
       console.log(taskPackage);
       var processIndex = 0;
       var packageArr = [];

       taskPackage.forEach((element) => {
        var metalObj = {
            "id": element.id,
            "netweight": element.weight_applied,
        }
            packageArr.push(metalObj);

        });

       var saveData = {
        "id": temptask_id,
        "weight_applied": taskPackage[processIndex].totalweightapplied,
        "weight_produced": taskPackage[processIndex].totalweightproduced,
        "loss_qty": taskPackage[processIndex].loss_qty,
        "task_id": module_id,
        "packagingtask": packageArr
        }
        
        /*if(taskPackage[processIndex].loss_qty > 5){
            if (!window.confirm("Loss % exceeds the threshold limit of 5%, Are you sure you want continue?")) {
                return;
            } 
        }
        if(taskPackage[processIndex]['reopened_status'] == 1){
            if(defaultWeightProduced > taskPackage[processIndex].totalweightproduced){
               if (!window.confirm("The weight must be greater than the previous weight, Are you sure you want continue?")) {
                return;
               }
            }
        }*/
        let msgtext = "";
        if(taskPackage[processIndex].loss_qty < 0 || taskPackage[processIndex].loss_qty > 5 || (taskPackage[processIndex]['reopened_status'] == 1 && (defaultWeightProduced > taskPackage[processIndex].totalweightproduced))){
            if(taskPackage[processIndex].loss_qty < 0){
                msgtext += "Wt. produced qty should not be greater than Wt. applied qty";
            }
            
            if(taskPackage[processIndex].loss_qty > 5){
                 if(msgtext !=''){
                     msgtext += " and ";
                 }
                 msgtext += "Loss % exceeds the threshold limit of 5%";
            }

            if(taskPackage[processIndex]['reopened_status'] == 1 && (defaultWeightProduced > taskPackage[processIndex].totalweightproduced)){
                if(msgtext !=''){
                    msgtext += " and ";
                } 
                msgtext += "The weight must be greater than the previous weight";    
            }
            swal({
                title: "Are you sure you want continue?",
                text: msgtext,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willContinue) => {
                console.log('w',willContinue);
                if (willContinue) {
                    saveDataPack(saveData, processIndex);
                }  
            }); 
        } else {
            saveDataPack(saveData, processIndex);
        }
        
       console.log("saveData", saveData);
        e.preventDefault();
        //return;
    //    axios.post(`${"production/taskpackageweightupdate"}`, saveData).then((response) => {
    //         console.log('data', response);
    //         if(response.data.success == true){
    //             console.log('data11', response);
    //             setDefaultWeightProduced(taskPackage[processIndex].totalweightproduced);
    //             swal("Success", response.data.message, "success", {
    //                 buttons: false,
    //                 timer: 2000
    //             })
    //         }
    //     }).catch(function(err){ console.log(err);swal("Failed", 'Something went wrong', "error"); });
    //     e.preventDefault();
    }

    const saveDataPack = (saveData, processIndex) => {
        axios.post(`${"production/taskpackageweightupdate"}`, saveData).then((response) => {
            console.log('data', response);
            if(response.data.success == true){
                console.log('data11', response);
                setDefaultWeightProduced(taskPackage[processIndex].totalweightproduced);
                swal("Success", response.data.message, "success", {
                    buttons: false,
                    timer: 2000
                })
            }
        }).catch(function(err){ console.log(err);swal("Failed", 'Something went wrong', "error"); });
        //e.preventDefault();
    }

    const saveOverrideData = (saveData) => {
        axios.post(`${"production/taskweightoverride"}`, saveData).then((response) => {
            console.log('data', response);
                if(response.data.success == true){
                    swal("Success", response.data.message, "success", {
                        buttons: false,
                        timer: 2000
                    });
                    gettaskdetail(); 
    
                } else {
                    swal("Failed", 'Something went wrong', "error");
                }
           }).catch(function(err){ console.log(err);swal("Failed", 'Something went wrong', "error"); });
    }

   const onchangeWeightHandel = (e) => {
        let regEx = /^(\d+(\.\d{0,4})?|\.?\d{1,4})$/;
        
        console.log('val', e.target.value);
        console.log("cindex", e.target.id);
        const { value } = e.target;
        console.log('value', value);
        // check if value includes a decimal point
        if (value.match(/\./g)) {
            const [, decimal] = value.split('.');

            // restrict value to only 2 decimal places
            if (decimal?.length > 2) {
               var newVal = parseFloat(value).toFixed(2);
            } else {
                var newVal = value;
            }
        } else {
            var newVal = value;
        }
        //console.log("pindex", parentindex);
        console.log('newVal', newVal);
        const values = [...taskPackage];
        values[e.target.id].weight_applied = newVal;
        var totalweight = values.reduce((total, obj) => parseFloat(obj.weight_applied) + parseFloat(total),0);
        console.log(totalweight);
        setTotalWeightApplied(totalweight);

        // var totalweight = values.reduce((total, obj) => parseInt(obj.weight_applied) + parseInt(total),0);
        // console.log(totalweight);
        // setTotalWeightApplied(totalweight);

        
        var percentage = values[0].loss_qty;
        var weight_produced = totalweight - (parseFloat(percentage) * parseFloat(totalweight)) / 100;
        console.log('weight_producedweight_produced', weight_produced);
        weight_produced = (weight_produced.toFixed(2));
        values[e.target.id].totalweightproduced = weight_produced;
        setTotalWeightProduced(weight_produced);
        setTaskPackage(values);

        console.log(taskPackage);
    //value = e.target.value;
    ///setFormValues(values);

        //console.log('psoo', this.taskPackage[parentindex]);
        //   setTaskPackage({
        //          ...taskPackage,
        //           [e.target.name]: e.target.value,
        //   });

        //console.log(e.target.value);
        // if(regEx.test(e.target.value)){ 
        // console.log(e.target.value);

        //      e.preventDefault();
        //      e.target.value = e.target.value;
        //     //  setEnteredData({
        //     //     ...enteredData,
        //     //     [e.target.name]: e.target.value,
        //     //   });
        //  } else {
        //     //setError('Email is invalid');
        //  }
    };

    const assignOperator = (val) => {
        
        if(val=='assign'){
            const userInfo = JSON.parse(localStorage.getItem('user'));
            var operator_user_id = userInfo.id;
        } else {
            var operator_user_id = null;
        }
         
        var readystatusData = { 
            task_id: task_id, 
            operator_user_id: operator_user_id, 
            tasktemp_id: temptask_id,
            type: tasktype
        }
        axios.post(`${"production/changeoperator"}`, readystatusData).then((response) => {
            console.log('data', response);
            if(response.data.success == true){
                console.log('data11', response);
                setOperatorid(operator_user_id);
                swal("Success", response.data.message, "success", {
                    buttons: false,
                    timer: 2000
                  })
            }
        }).catch(function(err){ console.log(err);swal("Failed", 'Something went wrong', "error"); });
    }

    const changeTask = (val) => {
        console.log(val);
        if(operatorid == 'null' || operatorid == null){
            swal("Failed", 'Operator Required', "error");
            return
        }
        const statusData = {
                status: val,
                rejection_note:  null,
                type: tasktype,
            }
        axios.put(`${"production/jobnew/"+ job_id +"/updatetaskstatus/"+ temptask_id + "/" +tasktype}`, statusData).then((response) => {
            console.log('data', response);
            if(response.data.success == true){
                gettaskdetail();
                if(val==3){
                    setStartBtnVisible(false);
                    setMKBtnVisible(true);
                } else if(val==4){

                    setMKBtnVisible(false);
                }
                swal("Success", response.data.message, "success", {
                    buttons: false,
                    timer: 2000
                  })
            } else {
                swal("Failed", 'Something went wrong', "error");
            }
        }).catch(function(err){ console.log(err);swal("Failed", 'Something went wrong', "error"); });
    }

    const dependencystatusupdate = () => {
        if(operatorid == 'null' || operatorid == null){
            swal("Failed", 'Operator Required', "error");
            return
        }
        var readystatusData = { id: task_id}
        axios.post(`${"production/jobnew/taskpartialreadyupdate"}`, readystatusData).then((response) => {
            console.log('data', response);
            if(response.data.success == true){
                setPartialStartBtnVisible(false);
                swal("Success", response.data.message, "success", {
                    buttons: false,
                    timer: 2000
                  })
            } else {
                swal("Failed", 'Something went wrong', "error");
            }
        }).catch(function(err){ console.log(err); swal("Failed", 'Something went wrong', "error");});
    }

    const openpdf = (btntype, jobnewheat_id) => {
        console.log(btntype);
        console.log(process_id);
        console.log(jobnewheat_id);
        var id = (jobnewheat_id && jobnewheat_id != undefined ? jobnewheat_id : temptask_id);
        //const configResponse = { responseType: 'blob' };
        console.log('url', "production/meltanalysis/" + btntype + "/" + id + "/" + process_id);
        const configResponse = { responseType: 'blob' };
         
        axios.get(`${"production/meltanalysis/" + btntype + "/" + id + "/" + process_id}`, {configResponse }).then((response) => {
            console.log('data', response.data);
            if(response){
                //console.log(response.data);
                var newBlob = new Blob([response.responseBlob], {
                    type: "application/pdf",
                });
                const data = window.URL.createObjectURL(newBlob);
                window.open(data);
            }
        });
    }

    const downloadAttachment = (name) => {
        axios.get(`${"jobinstructionfiledownload/" + name}`).then((response) => {
            console.log('data', response.config.url);
            if(response){
                console.log('insideresponse');
                window.open(response.config.url);
            }
        });
      }

      const markComplete = (id, status) => {
        if(operatorid == 'null' || operatorid == null){
            swal("Failed", 'Operator Required', "error");
            return
        }
        if(status !==1) {
            axios.get(`${"production/jobnew/" + job_id + "/updateheatstatus/" + id}`).then((response) => {
                console.log('data', response.data);
                if(response.data){
                    console.log('SUCCES');
                }

            });
        }
        
         
      }
   // End For Number Pad
    return(
            <div>
                {loading ? (
                <div className="loderDiv">  
                <RevolvingDot
                height="60"
                width="60"
                radius="28"
                color="#179BDF"
                ariaLabel="watch-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
                />
                </div>  
                ): ("")
            } 
                    <div className="flex justify-between items-center border-b-[1px] border-slate-150 pb-2">
                    <div className="flex items-center space-x-2">
                       <h2 className="text-base font-bold">{records.jobid}-Package</h2>
                       <div className="flex">
                                <div className="border border-blue-600">
                                {taskTime && <span className="text-black flex items-center justify-center flex-row my-1">
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </span>
                                        <span className="pl-1 text-sm font-bold">{taskTime}</span>
                                    </span>}
                                    <span className="text-xs font-semibold text-white bg-blue-600 text-center block px-4 py-1.5">{taskStatus}</span>
                                </div> 
                        </div>
                        </div>
                       
                       <div className="flex">
                       {<button type="button" onClick={handelPallets} className="rounded-sm bg-orange-500 hover:bg-orange-400 text-white font-semibold text-[14px] px-4 py-6 flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                                <span className="ml-1">Update Pallets</span> 
                            </button>}
                       {startBtnVisible && <button type="button" onClick={() => changeTask(3)} type="button" className="rounded-sm ml-2 bg-green-600 hover:bg-green-500 text-white font-semibold text-[14px] px-4 py-5 flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
                                </svg>
                                <span className="ml-1">Start Task</span> 
                            </button>
                        }
                            {mkBtnVisible && <button type="button" onClick={() => changeTask(4)} className="rounded-sm ml-2 bg-green-600 hover:bg-green-500 text-white font-semibold text-[14px] px-4 py-5 flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span className="ml-1">Mark All Completed</span> 
                            </button>
                            }
                            
                        </div>
                   </div>
                   {/* Update Pallets */}
                   {
                        updatePallets && <UpdatePallets closePallets={() => setupdatePallets(false)} palletParamDetails={palletData} handelPallets={updatePallets} />
                    }
                   {/* End Update Pallets */}

                   <div>
                        <div className="flex items-center justify-between flex-wrap pt-2">
                        <div className="flex flex-wrap items-center space-x-2">
                              <h2 className="text-base font-bold">{records.taskdescription} - {records.completiondate}</h2>
                            
                           </div>
                           <div className="flex items-center justify-end space-x-2">
                           
                           {records.tasknotes && <div className="flex items-center relative">
                           <button type="button" className="text-sky-600 underline text-xs font-semibold flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                </svg>
                                  <span className="ml-1 text-base lg:text-xs" onClick={handelImportantNote}>Important Notes</span> 
                             </button>
                           </div>
                           }
                           <div className="flex items-center">
                                  <button type="button" className="text-sky-600 underline text-xs font-semibold flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                        </svg>
                                        <span className="ml-1 text-base lg:text-xs" onClick={handelInstruction}>Work Instruction</span> 
                                    </button>
                                </div>
                                {taskOperator && 
                           <span>
                                    {(operatorid == null || operatorid == "null") ? (    
                                    <button type="button" onClick={() => assignOperator('assign')} className="rounded-sm text-white text-base font-medium flex items-center bg-green-600 ml-3 py-3 px-3">
                                        <span>Assign to Me</span> 
                                    </button>
                                    ) : (
                                    <button type="button" onClick={() => assignOperator('unassign')} className="rounded-sm text-white text-base font-medium flex items-center bg-red-600 ml-3 py-3 px-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                        <span>Un-Assign</span> 
                                    </button>
                                     )}
                            </span>
                        }
                           </div>
                        </div>


                        <div className="flex pt-3 pb-2">
                            <div className="space-y-2">
                                <div> 
                                    <h3><span className="text-base font-bold">Customer Order: </span>
                                    <span className="text-base font-medium">
                                    { 
                                        records && records.joborderitems? records.joborderitems.map((joborderitemdetail,index) => (
                                            <span><span className="text-green-600 dark:text-green-500">{joborderitemdetail.customer } </span> {" - " + joborderitemdetail.order_id + ' - ' + joborderitemdetail.item_name}
                                            <span className="text-base font-bold"> - Style: </span>{joborderitemdetail.sp_name }  <span className="text-base font-bold"> - Package: </span>{joborderitemdetail.ip_name }  <span className="text-base font-bold"> - Size: </span> { joborderitemdetail.is_name}<span className="text-base font-bold"> - UOM: </span>{ joborderitemdetail.uom_name} {index>0?" |  ":""}<br/></span>
                                        )):("")
                                    }
                                    </span>
                                    </h3>
                                </div>
                                
                                <div className="flex items-center">
                                    <h3><span className="text-base font-bold">Workstation:</span><span className="text-base font-medium"> {workstation_name} <span className="text-red-600">({loss_percentage}% loss)</span></span>
                                        {records.process_dependency !=null && 
                                        <span><span className="text-base font-bold"> | Dependency Task : </span><span className="text-green-600">{records.process_dependency}</span></span>
                                        }
                                    </h3>
                                </div>
                              
                            </div>

                           
                            {partialstartBtnVisible &&
                            <div className="w-3/12 flex items-center flex-col justify-center space-y-2">
                                
                               <button type="button" onClick={() => dependencystatusupdate()} type="button" className="rounded-sm text-white text-[15px] font-medium flex items-center bg-green-600 hover:bg-green-500 ml-1 p-5 py-4">
                                    <span className="ml-1">Partial Ready</span>
                                </button>
                              
                            </div>
                                 }
                            <div className="flex flex-col mt-6">
                                
                                     
                            {/* Task Instruction image popup*/}
                            <Transition.Root show={openInstruction} as={Fragment}>
                                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setopenInstruction}>
                                        <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                        >
                                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                        </Transition.Child>

                                        <div className="fixed inset-0 z-10 overflow-y-auto">
                                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                            <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                            <Dialog.Panel className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-2xl">
                                                <div>
                                                    
                                                    <div className="flex justify-between items-center bg-gray-200 py-3 px-3">
                                                        <Dialog.Title as="h3" className="text-lg font-bold">
                                                         Task Instruction:
                                                        </Dialog.Title>
                                                        <span onClick={() => setopenInstruction(false)} ref={cancelButtonRef} className="cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div className="py-8 px-4">
                                                        <div className="pb-4 text-lg">{taskInstruction.description?taskInstruction.description.replace(/(<([^>]+)>)/ig, '') : '' }
                                                        </div>
                                                       <div className="table-wrp block max-h-96 overflow-y-auto">
                                                       <table className="w-full text-left border border-slate-300">
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="2" className="whitespace-nowrap px-4 py-3 font-semibold  text-slate-800 text-sm border border-slate-300">
                                                                            <div className="flex">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                                                                                </svg>
                                                                                <span>Attachments</span> 
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="whitespace-nowrap w-[80%] bg-slate-200  px-4 py-2 font-semibold   text-slate-800 text-xs border border-slate-300">
                                                                            Name
                                                                        </th>
                                                                        <th className="whitespace-nowrap w-[20%] bg-slate-200  px-4 py-2 font-semibold  text-slate-800 text-xs border border-slate-300">
                                                                            Download
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                {taskInstruction.attachments && taskInstruction.attachments.map((instruction,index) => (
                                                                    <tr>
                                                                        <td className="whitespace-nowrap font-medium text-slate-700 text-base px-4 py-1 border border-slate-200 py-5">{instruction.orig_name}</td>
                                                                        <td className="whitespace-nowrap font-medium text-slate-700 text-xs border border-slate-200 text-center"> 
                                                                            <button onClick={() => downloadAttachment(instruction.name)} type="button" className="flex justify-center items-center h-24 w-full">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 text-orange-400">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                                                </svg>
                                                                            </button> 
                                                                        </td>
                                                                    </tr>
                                                                ))}     
                                                                 </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                        </div>
                                    </Dialog>
                              </Transition.Root>
                            {/* End Task Instruction image popup*/}
                            {/* Important notes popup*/}
                            <Transition.Root show={openItemDetail} as={Fragment}>
                                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenItemDetail}>
                                        <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                        >
                                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                                        </Transition.Child>

                                        <div className="fixed inset-0 z-10 overflow-y-auto">
                                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                            <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                            >
                                            <Dialog.Panel className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-2xl">
                                                <div>
                                                    
                                                    <div className="flex justify-between items-center bg-gray-200 py-3 px-3">
                                                        <Dialog.Title as="h3" className="text-lg font-bold">
                                                         Important Notes:
                                                        </Dialog.Title>
                                                        <span onClick={() => setOpenItemDetail(false)} ref={cancelButtonRef} className="cursor-pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div className="py-8 px-4">
                                                        <div className="pb-4 text-lg">{records.tasknotes}
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                
                                            </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                        </div>
                                    </Dialog>
                              </Transition.Root>
                            {/* End Item Detail popup*/}

                            </div>
                           
                        </div>

                   </div>
                  
                  {/* <form className="border-[1px] border-slate-150 relative mt-4 mb-8">
                        <div className="flex justify-start items-center flex-wrap  p-4">
                            <div className="flex items-center justify-start w-full">
                                <div className="flex items-start w-2/5 flex-col pr-2">
                                   <span className="text-sm font-semibold mb-1">Total Weight Applied:</span>
                                   <div className="flex w-full items-center">
                                   <input type="number"  value={enteredData.TotalWeight}  name="TotalWeight" onChange={onchangepackagingHandel} className="px-2 w-[90%] py-3 bg-gray-50 border border-gray-300 text-gray-900 text-sm font-medium rounded-none " />
                                       <span className="text-sm ml-1 w-[10%]">lbs</span>
                                    </div> 
                                </div>
                                <div className="flex items-start flex-col w-1/5">
                                    <span className="text-sm font-semibold mb-1">Loss%:</span>
                                   <div className="flex w-full items-center">
                                       <input type="number" value={enteredData.lossdata}  name="lossdata"  onChange={onchangepackagingHandel} className="px-2 py-3 w-[80%] bg-gray-50 border border-gray-300 text-gray-900 text-sm font-medium rounded-none" />
                                       <span className="text-sm ml-1 w-[20%]">%</span>
                                    </div>
                                </div>
                                <div className="flex items-start flex-col w-2/5 pl-2">
                                    <span className="text-sm font-semibold mb-1">Weight Produced:</span>
                                   <div className="flex w-full items-center">
                                       <input type="number"  value={enteredData.weightProduced}  name="weightProduced"  onChange={onchangepackagingHandel} className="px-2 py-3 w-[90%] bg-gray-50 border border-gray-300 text-gray-900 text-sm font-medium rounded-none " />
                                       <span className="text-sm ml-1 w-[10%]">lbs</span>
                                    </div>
                                </div>
                              
                            </div>
                       </div>
                   
                           <div className="flex justify-end items-center mt-2 bg-gray-100 p-3">
                               <div className="flex justify-end items-center">
                                    <span className="text-sm font-bold">Total: <span className="text-sm font-semibold">7720.00</span>
                                    </span>
                                </div>
                            </div>
                            </form> */}
                    <div classame="packaging mb-8">
                        <div className="flex justify-between">
                            <span className="flex items-center">
                                <h2 className="text-lg font-bold">Task Packaging</h2>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                                <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clipRule="evenodd" />
                            </svg>
                            </span>
                             
                        </div>
                        <div className="pt-4">
                            {/* Table Start  overflow-y-auto max-h-96 */}
                           {/* {taskPackage.map((process,index) => (*/}
                                <div className="tableWrapper border-[1px] border-slate-400 relative mb-2">
                           <span className="absolute text-base font-semibold bg-white px-2 py-2 top-[-22px] left-[12px]">{taskPackage[0]?taskPackage[0].processname:''}</span>
                                    
                                    <form className=" relative mt-4 mb-4">
                                        <div className="flex justify-start items-center flex-wrap pt-0 p-4">
                                            <div className="flex items-center justify-start w-full">
                                                <div className="flex items-start w-2/5 flex-col pr-2">
                                                <span className="text-sm font-semibold mb-1">Total Weight Applied:</span>
                                                <div className="flex w-full items-center">
                                                    <input type="number" value={parseFloat(totalWeightApplied).toFixed(2)} name="packagingTotalWeight" onChange={onchangeprocessHandel} className="px-2 w-[90%] py-3 bg-gray-50 border border-gray-300 text-slate-400 text-sm font-medium rounded-none " readOnly />
                                                    <span className="text-sm ml-1 w-[10%]">lbs</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-start flex-col w-1/5">
                                                    <span className="text-sm font-semibold mb-1">Loss%:</span>
                                                <div className="flex w-full items-center">
                                                    <input type="number" onFocus={(e) => e.target.value ==0 ? e.target.value="" : e.target.value} value={totalPercentage} name="packaginglossdata" onChange={onchangeprocessHandel} 
                                                    className={"px-2 py-3 w-[80%] border "+(!mkBtnVisible ? 'bg-gray-50' : '') +" border-gray-300 text-gray-900 text-sm font-medium rounded-none"} readOnly={!mkBtnVisible} />
                                                    <span className="text-sm ml-1 w-[20%]">%</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-start flex-col w-2/5 pl-2">
                                                    <span className="text-sm font-semibold mb-1">Total Weight Produced:</span>
                                                <div className="flex w-full items-center">
                                                    <input type="number" onFocus={(e) => e.target.value ==0 ? e.target.value="" : e.target.value} value={totalWeightProduced} name="packagingweightProduced" onChange={onchangeprocessHandel} 
                                                    className={"px-2 py-3 w-[90%] border "+(!mkBtnVisible ? 'bg-gray-50' : '') +" border-gray-300 text-gray-900 text-sm font-medium rounded-none"} readOnly={!mkBtnVisible} />
                                                    <span className="text-sm ml-1 w-[10%]">lbs</span>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                    </div>
                            
                                    <div className="flex justify-end items-center px-4">
                                    {mkBtnVisible && <button type="button" onClick = {handleSubmit}  className="rounded-sm bg-sky-600 hover:bg-sky-500 text-white text-[16px] font-medium flex items-center ml-1 p-5 py-4">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                </svg>
                                                <span className="ml-1">Save</span>
                                            </button>}
                                        </div>
                                
                                <div className="flex items-center items-stretch p-2">
                                    <div className="overflow-x-auto w-full">
                                        <table className="w-full text-left border border-slate-300">
                                            <thead>
                                                <tr>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-2 font-semibold text-slate-800 text-base border border-slate-300">Item Name</th>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3.5 font-semibold text-slate-800 text-base border border-slate-300">Package Name</th>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3.5 font-semibold text-slate-800 text-base border border-slate-300">Lot#</th>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3.5 font-semibold text-slate-800 text-base border border-slate-300">Pcs</th>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3.5 font-semibold text-slate-800 text-base border border-slate-300">Pkg Qty</th>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3.5 font-semibold text-slate-800 text-base border border-slate-300">Pkg Wt</th>
                                                <th className="whitespace-nowrap bg-slate-200 px-4 py-3.5 font-semibold text-slate-800 text-base border border-slate-300">Net Wt</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {taskPackage.map((item,itemindex) => (
                                                <tr>
                                                    <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3 border border-slate-200">{packItemName}</td>
                                                    <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3 border border-slate-200">{item.itempack_name}</td>
                                                    <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3 border border-slate-200">{item.custom_lot ? item.lot_number + "|" + item.custom_lot : item.lot_number }</td>
                                                    <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3 border border-slate-200">{item.pcs}</td>
                                                    <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3 border border-slate-200">{parseFloat(item.packageqty).toFixed(2)}</td>
                                                    <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3 border border-slate-200">{parseFloat(item.packageweight).toFixed(2)}</td>
                                                    <td className="whitespace-nowrap font-medium text-slate-700 text-xs px-4 py-3 border border-slate-200">
                                                        <input type="number" step="0.1" onFocus={(e) => e.target.value ==0 ? e.target.value="" : e.target.value} value={item.weight_applied} name="weight_applied" id={itemindex}  onChange={onchangeWeightHandel}  
                                                        className={"px-1 py-2 w-24 "+(!mkBtnVisible ? 'bg-gray-50' : '') +" border border-gray-300 text-gray-900 text-xs font-medium rounded-none"} readOnly={!mkBtnVisible}
                                                         /> 
                                                    </td>
                                                </tr>
                                            ))}   
                                            </tbody>
                                        </table>
                                        
                                    </div>
                                    
                                </div>
                                </form>

                            </div>
                            {/*))}*/}
                                {/* Table End  */}
                                 
                        </div>
                </div>
        </div>
    )
}