import axios from "axios";
import {React, useState} from "react";
import {Tasklist} from "./TaskList";
import {UnassignedTasks} from "./UnassignedTasks";
import {CompletedTasks} from "./CompletedTasks";
export const TaskList =() =>{
   
    const [assignedTask, setassignedTask] = useState(true);
    const [unassignedTask, setunassignedTask] = useState(false);
    const [completedTask, setcompletedTask] = useState(false);
    const handelsassignedTask = () => {
           setassignedTask(true);
           setunassignedTask(false);
           setcompletedTask(false);
     };
    const handeunassignedTask = () => {
        setunassignedTask(true);
        setassignedTask(false);
        setcompletedTask(false);
    }
    const handlecompletedTask = () => {
        setunassignedTask(false);
        setassignedTask(false);
        setcompletedTask(true);
    }
    
    return(
       <div className="h-full bg-blue200 py-5">
            <div className="mx-auto px-4">
               <div>
                <div>
                    <div className="tablistflex flex justify-between">
                        <div className="flex space-x-2">
                            <span onClick={handelsassignedTask} className={`${assignedTask ? "bg-gray800 text-white" : "bg-slate-300 "} inline-block text-gray800 cursor-pointer text-lg font-semibold px-8 py-5`}>Your Tasks</span>
                            <span onClick={handeunassignedTask} className={`${unassignedTask ? "bg-gray800 text-white" : "bg-slate-300 "} inline-block text-gray800 cursor-pointer text-lg font-semibold px-8 py-5`}>Unassigned Tasks</span>
                        </div>
                        <span onClick={handlecompletedTask} className={`${completedTask ? "bg-gray800 text-white" : "bg-slate-300 "} inline-block text-gray800 cursor-pointer text-lg font-semibold px-8 py-5`}>Completed Tasks</span>
                    </div>
                  
                    <div className="border-2 border-slate-150 p-5 bg-white">
                        <div className="tasklist min-h-[500px]"> 
                           {assignedTask &&
                              <Tasklist />
                            } 
                            {unassignedTask &&
                              <UnassignedTasks />
                            } 
                            {completedTask &&
                              <CompletedTasks />
                            } 
                        </div>
                    </div>
                 
                </div>
               
                </div>
            </div>
        </div>
     )
}