import {React, useState} from "react";
import {TaskLogtable} from './TaskLog';
import {ProcessTable} from './ProcessTable';
import {PackagingTable} from './PackagingTable';
import {OtherTable} from './OtherTable';
import { useLocation } from 'react-router-dom';
import {Success} from './Success';
import {Error} from './Error';
import {Notes} from './Notes';
// For Number Pad
const initialValues = {
    TotalWeight: '',
    lossdata: '',
    weightProduced: ''
  };
// End For Number Pad
export const CreateBillet = () =>{
    const location = useLocation();
    console.log(location.pathname);
    const mystr=location.pathname;
    const  myarr = mystr.split("/");
    console.log(myarr);
    const taskType = myarr[2];
    const state = location.state;
    //console.log('work name', state);
    
    const [TaskBlk, setTaskBlk] = useState(true);
    const [TaskLog, setTaskLog] = useState(false);
    const [imNote, setimNote] = useState(false);
    //const [error, setError] = useState(null);
    // For Number Pad
    const [enteredData, setEnteredData] = useState(initialValues);
    const onchangeHandel = (e) => {
        let regEx = /^\d*\.?\d{0,2}$/;
        console.log(e.target.value);
        ///^(\d+(\.\d{0,4})?|\.?\d{1,4})$/;
        if(regEx.test(e.target.value)){ 
             e.preventDefault();
             setEnteredData({
                ...enteredData,
                [e.target.name]: e.target.value,
              });
         } 
    };
   // End For Number Pad
    const handelsassignedTask = () => {
            setTaskBlk(true);
            setTaskLog(false);
     };
     const handeunassignedTask = () => {
        setTaskLog(true);
        setTaskBlk(false);
    };
  
    return(
        <div className="bg-blue200 py-5">
           <div className="mx-auto px-4">
          
                <div>
                   <div className="tablistflex">
                        {/*<span onClick={handelsassignedTask} className={`${TaskBlk ? "bg-gray800 text-white" : "bg-slate-300"} cursor-pointer inline-block text-gray800 text-lg font-semibold px-8 py-5`}>Task Edit</span>*/}
                        {/*<span onClick={handeunassignedTask} className={`${TaskLog ? "bg-gray800 text-white" : "bg-slate-300"} cursor-pointer inline-block text-gray800 text-lg font-semibold px-8 py-5`}>Task Log</span>*/}
                    </div>
                </div>
                <div className="border-2 border-slate-150 px-5 py-3 bg-white min-h-[400px]">
                {/* Task Edit */}
                {TaskBlk && 
                 
                    <div>
                   
                         {taskType === "Process" && (<ProcessTable onChangeNumber={onchangeHandel}/>)}

                         {taskType === "Packaging" && (<PackagingTable onChangeNumber={onchangeHandel} />)}

                         {taskType === "Other" && (<OtherTable onChangeNumber={onchangeHandel} />)}
                        
                        {/* Add Nots */}
                            <Notes />
                         {/* End Nots */}
                    </div>
                  
                   }
                  {/* End Task Edit */}
                    {/* TaskLog */}
                       {TaskLog &&
                            <div>
                                <h2 className="text-sm font-semibold">My Tasks - Activity Log</h2> 
                                <TaskLogtable />  
                            </div>
                        }
                   {/* End TaskLog */}

                     {/* Success Massage */}
                     <Success />
                    {/* End Success Massage */}
                     {/* Success Massage */}
                       <Error />
                    {/* End Success Massage */}
                   
                </div>
            </div>
        </div>
    )

}