import axios from "axios";
import {React, useState, useEffect} from "react";
import { Link} from 'react-router-dom';
import { TfiPackage, TfiLayoutColumn3 } from "react-icons/tfi";
import {BsArrowDownUp} from "react-icons/bs";
import { MdCleaningServices} from "react-icons/md";
import { RevolvingDot } from 'react-loader-spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const CompletedTasks = () => {
    const [operatortasklist, setCompletedTasks] = useState([]);
    const [jobId, setJobId] = useState("");
    const [createdDate, setCreatedDate] = useState("");
    const [completionDate, setCompletionDate] = useState("");
    const [taskDesc, setTaskDesc] = useState("");
    const [taskPriority, setTaskPriority] = useState("");
    const [sortType, setSortType] = useState("DESC");
    const [sortBy, setSortBy] = useState("jobid");

    const token = localStorage.getItem('accessToken');

   // console.log(token);
       //console.log(token);
    //const headers = { 'Authorization': 'Bearer '+token };
    const [loading, setLoading] = useState(false);

    //console.log(headers);
    const getcompletedtasklist = () => {
       
        setLoading(true);
        setJobId("");
        setCreatedDate("");
        setCompletionDate("");
        setTaskDesc("");
        setTaskPriority("");
        axios.get('production/mytasklist?taskstatus=Completed&type=tab').then((response) => {
        
       // console.log(response.data);
            setCompletedTasks(response.data.data);
            setLoading(false);
        });
       
    };

    const getsearchfilter = () => {
        var filter = new Array();
        if(jobId!='') {
            var obj = { name: "jobid", operator: "like", value: jobId,};
            filter.push(obj);
        }
        if(createdDate!=='' && createdDate!==null) {
            console.log('createdDate', createdDate);
            var day =  createdDate.getDate();
            var dateNumber = ("0" + day).slice(-2);
            var monthNumber = ("0" + (createdDate.getMonth() + 1)).slice(-2);
            var yearNumber = createdDate.getFullYear();
            var dateString = `${yearNumber}-${monthNumber}-${dateNumber}`;
            var finalDateString = dateString;

            var obj1 = { name: "created_at", operator: "like", value: dateString,};
            console.log('createdDatesss', finalDateString);
            filter.push(obj1);
        }
        if(completionDate!=='' && completionDate!==null) {
            console.log('completionDate', completionDate);
            var day =  completionDate.getDate();
            var dateNumber = ("0" + day).slice(-2);
            var monthNumber = ("0" + (completionDate.getMonth() + 1)).slice(-2);
            var yearNumber = completionDate.getFullYear();
            var dateString = `${yearNumber}-${monthNumber}-${dateNumber}`;
            var finalDateString = dateString;

            var objdat = { name: "completiondate", operator: "like", value: dateString,};
            console.log('completionDatesss', finalDateString);
            filter.push(objdat);
        }  
        if(taskDesc!='') {
            var obj = { name: "title", operator: "like", value: taskDesc,};
            filter.push(obj);
        }
        console.log(taskPriority);
        if(taskPriority!='') {
            var obj = { name: "priority", operator: "like", value: taskPriority,};
            filter.push(obj);
        }
        console.log(filter);
        var search = '';
        if (filter.length > 0) {
            search =  btoa(JSON.stringify(filter));
        }
        return search;
    };


    const [shortArrow, setShortArrow] = useState('');
    const getsorttasklist = (val) => {
        console.log("skdkdkdk");
        setShortArrow(val);
        setLoading(true);
        setSortBy(val);
        if(sortType=="DESC"){
            setSortType("ASC");
        } else {
            setSortType("DESC");
        }
        
        var search = getsearchfilter();
        //API Call
        axios.get('production/mytasklist?taskstatus=Completed&type=tab&search='+search+'&sortby='+val+'&sorttype='+sortType).then((response) => {
            setCompletedTasks(response.data.data);
            setLoading(false);
        });
    };

    const getsearchtasklist = () => {
        setLoading(true);
        var search = getsearchfilter();
        //API Call
        axios.get('production/mytasklist?taskstatus=Completed&type=tab&search='+search).then((response) => {
            setCompletedTasks(response.data.data);
            setLoading(false);
        });
    };

    const onchangeHandel = (e) => {
        console.log('e', e);
        console.log('name', e.target.name);
        if(e.target.name == 'jobid'){
            setJobId(e.target.value);
        } else if(e.target.name == 'createddate') {
            setCreatedDate(e.target.value);
        } else if(e.target.name == 'completiondate') {
            setCompletionDate(e.target.value);
        } else if(e.target.name == 'taskdesc') {
            setTaskDesc(e.target.value);
        } else if(e.target.name == 'taskpriority') {
            setTaskPriority(e.target.value);
        }
   };

    useEffect(() => {
        getcompletedtasklist();
      },[]);
   return(
    <>
     {loading ? (
    <div className="loderDiv">  
       <RevolvingDot
       height="60"
       width="60"
       radius="28"
       color="#179BDF"
       ariaLabel="watch-loading"
       wrapperStyle={{}}
       wrapperClassName=""
       visible={true}
     />
    </div>  
     ): ("")} 
         <div className="flex justify-between items-center">
            <h2 className="text-base font-semibold">Completed - Tasks(Past 7 days)</h2>
            <div className="flex space-x-2">
               <div className="flex space-x-2">
                    <span className="flex items-center text-green-700 space-x-1">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                        </svg>
                        <span className="text-sm">
                             Created Date                   
                        </span>
                                                
                    </span>
                    <span className="flex items-center text-blue-700 space-x-2 font-bold">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                        </svg>
                        <span className="text-sm">
                              Est.Completion Date                   
                        </span>
                                                
                    </span>
                    </div>
                    <button type="button" className="bg-amber-200 hover:bg-amber-300 text-gray-900 font-semibold text-lg px-5 py-4 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                        <span className="ml-1" onClick={getcompletedtasklist}>Refresh</span> 
                   </button>
            </div>
           
        </div>
        <div className="flex space-x-3 mt-2">
                <input id="default-search" value={jobId} name="jobid" onInput={onchangeHandel} className="block w-full p-4 text-sm text-gray-900 border border-current focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Job Id" required />
                {/*<input id="default-search" value={taskDesc} name="taskdesc" onInput={onchangeHandel} className="block w-full p-4 ext-sm text-gray-900 border border-current	 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Task Description" required />*/}
                <DatePicker selected={createdDate}  onChange={(date) => setCreatedDate(date)} className="block w-full p-4 ext-sm text-gray-900 border border-current focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" wrapperClassName="w-full" placeholderText="Created Date" />  
                <DatePicker selected={completionDate}  onChange={(date) => setCompletionDate(date)} className="block w-full p-4 ext-sm text-gray-900 border border-current focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" wrapperClassName="w-full" placeholderText="Comp.Date" />  
                <select name="taskpriority" onChange={onchangeHandel} value={taskPriority}  className="w-full p-2.5 text-gray-500 bg-white border border-current rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600">
                 <option value="">Select Priority</option>
                    <option value="High">High</option> 
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                </select>
             
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-4" onClick={getsearchtasklist}>
                        Search
                </button>
       
        </div>
        <div className="flex justify-end items-center w-full py-1 space-x-2 font-bold">
        
               
                </div>
                <div className="overflow-x-auto">
                <table className="w-full text-left border-spacing-y-[1px] border-separate">
                    <thead>
                            <tr> 
                                <th className="whitespace-nowrap px-3 py-3 font-semibold uppercase text-white !text-[14px] bg-[#5d799f] text-center">
                                    <div className="flex space-x-2">
                                    <span><BsArrowDownUp className={`${shortArrow === 'created_at' ? "opacity-100": "opacity-50" } text-white text-xl cursor-pointer`} onClick={() => getsorttasklist('created_at')} /></span>
                                    <span>Task</span>
                                    </div>
                                <div className="flex w-full relative">
                                    <span className="triangle_down"></span>
                                </div>
                                </th>
                                <th className="whitespace-nowrap px-3 py-3 font-semibold uppercase text-white !text-[14px] bg-[#5d799f] text-center">
                                
                                <div className="flex space-x-2">
                                        <span><BsArrowDownUp className={`${shortArrow === 'workstation_name' ? "opacity-100": "opacity-50" } text-white text-xl cursor-pointer`} onClick={() => getsorttasklist('workstation_name')}/></span>
                                        <span>Workstation</span>
                                    </div>
                                <div className="flex w-full relative">
                                    <span className="triangle_down"></span>
                                </div>
                                </th>
                                
                                <th className="whitespace-nowrap px-3 py-3 font-semibold uppercase text-white !text-[14px] bg-[#5d799f] text-center">
                                    
                                <div className="flex space-x-2">
                                        <span><BsArrowDownUp className={`${shortArrow === 'taskstatus' ? "opacity-100": "opacity-50" } text-white text-xl cursor-pointer`} onClick={() => getsorttasklist('taskstatus')}/></span>
                                        <span>Status</span>
                                    </div>
                                    <div className="flex w-full relative">
                                    <span className="triangle_down"></span>
                                </div>
                                </th>
                                <th className="whitespace-nowrap px-3 py-3 font-semibold uppercase text-white !text-[14px] bg-[#5d799f] text-center">
                                    &nbsp;
                                </th>
                            </tr>
                        </thead>
                        {operatortasklist.length ? (
                            <tbody>
                            {operatortasklist.map((tasklisted,index) => (
                                <tr key={index}>
                                <td className="flex items-center whitespace-nowrap text-slate-800 font-semibold bg-slate-200 space-y-2.5 first:rounded-l-md last:rounded-r-md shadow-[20px_3px_20px_#0000000b]">
                                <Link to={"/createBillet/"+tasklisted.tasktype+"/"+tasklisted.taskid+"/"+tasklisted.workstation_id+"/"+tasklisted.operator_user_id+"/"+tasklisted.temp_taskid} 
                                state={{module_id:tasklisted.id, work_name:tasklisted.workstation_name, loss_per:tasklisted.workstation_loss, job_id:tasklisted.job_id}} className="flex flex-col w-full justify-center p-2">
                                    <div className="flex items-center">
                                        <span className="px-1 text-sm font-bold flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                                </svg>
                                                {tasklisted.jobid}
                                            </span>
                                            {tasklisted.priority=='High' &&
                                            <span className="px-1 py-[2px] text-[12px] flex items-center text-red-600 ml-2 rounded-md border border-red-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3.5 h-3.5">
                                                <path fillRule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clipRule="evenodd" />
                                                </svg>
                                                <span className="font-semibold">High</span>
                                            </span>
                                            }
                                            {tasklisted.priority=='Medium' &&
                                            <span className="px-1 py-[2px] text-[12px] flex items-center text-amber-600 ml-2 rounded-md border border-amber-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3.5 h-3.5">
                                            <path fillRule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clipRule="evenodd" />
                                            </svg>
                                            <span className="font-semibold">Medium</span>
                                        </span>
                                            }
                                            {tasklisted.priority=='Low' &&
                                        <span className="px-1 py-[2px] text-[12px] flex items-center text-gray-500 ml-2 rounded-md border border-gray-500">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-3.5 h-3.5">
                                        <path fillRule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clipRule="evenodd" />
                                        </svg>
                                        <span className="font-semibold">Low</span>
                                    
                                        </span>
                                            }
                                        </div>
                                        <div className="flex items-center mt-2">
                                            
                                            <span className="px-1 text-sm flex items-center ">
                                            {tasklisted.tasktype=='Process' && <TfiLayoutColumn3  className="w-5 h-5 text-sky-600"/>}
                                            {tasklisted.tasktype=='Packaging' &&  <TfiPackage  className="w-5 h-5 text-sky-600"/>} 
                                            {tasklisted.tasktype=='Other' &&  <MdCleaningServices  className="w-5 h-5 text-sky-600"/>} 
                                            <span className="text-sky-600 pl-1">{tasklisted.title}</span>
                                            </span>
                                            
                                        </div>
                                        <div className="flex items-center mt-2">
                                            
                                        
                                            <span className="px-1 text-[13px] flex items-center text-green-700">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                                    </svg>
                                                    <span className="pl-1">
                                                    {tasklisted.created_at}
                                                    </span>
                                                    
                                                </span>
                                                <span className="px-1 text-[13px] flex items-center text-blue-700">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                                    </svg>
                                                    <span className="pl-1">
                                                    {tasklisted.completiondate}
                                                    </span>
                                                    
                                                </span>
                                        </div>
                                    </Link>
                                </td>
                                <td className="whitespace-nowrap font-semibold border-b-1 border-slate-200 first:rounded-l-md last:rounded-r-md bg-slate-200 space-y-2.5 shadow-[20px_3px_20px_#0000000b]">
                                <Link to={"/createBillet/"+tasklisted.tasktype+"/"+tasklisted.taskid+"/"+tasklisted.workstation_id+"/"+tasklisted.operator_user_id+"/"+tasklisted.temp_taskid} 
                                state={{module_id:tasklisted.id, work_name:tasklisted.workstation_name, loss_per:tasklisted.workstation_loss, job_id:tasklisted.job_id}} className="flex flex-col w-full justify-center px-2"> 
                                        <div className="flex flex-col space-y-2.5 ">
                                            <span className="px-2 text-sm text-slate-800 flex">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                                                </svg>
                                                <span className="pl-1">{tasklisted.workstation_name}</span>
                                            </span>
                                            <span className="px-2 text-sm flex items-center process">
                                                <span className="w-2.5 h-2.5 rounded-full bg-sky-500"></span>
                                                <span className="ml-1 text-gray-800"><span>{tasklisted.tasktype=='Other' && 'Ad hoc'}
                                                {tasklisted.tasktype=='Process' && 'Process' }  {tasklisted.tasktype=='Packaging' && 'Packaging' }</span><span className='ml-1'>({tasklisted.heats})</span></span>
                                            </span>
                                        </div>
                                    </Link>
                                    </td>
                                
                                    <td className="whitespace-nowrap text-slate-800 font-semibold border-b-1 border-slate-200 first:rounded-l-md last:rounded-r-md bg-slate-200 space-y-2.5 shadow-[20px_3px_20px_#0000000b]">
                                    <Link to={"/createBillet/"+tasklisted.tasktype+"/"+tasklisted.taskid+"/"+tasklisted.workstation_id+"/"+tasklisted.operator_user_id+"/"+tasklisted.temp_taskid} 
                                state={{module_id:tasklisted.id, work_name:tasklisted.workstation_name, loss_per:tasklisted.workstation_loss, job_id:tasklisted.job_id}} className="flex flex-col w-full justify-center px-2">
                                     { tasklisted.taskstatus==='Completed' && <div className="flex items-center justify-center statuinprogress">
                                                    <span className="px-1 text-[12px] text-white bg-slate-500 px-2 py-1 rounded">Completed</span>
                                                </div>}
                                        { tasklisted.taskstatus==='QA Pending' && <div className="flex items-center justify-center statuinprogress">
                                            <span className="px-1 text-[12px] text-white bg-cyan-500 px-2 py-1 rounded">QA Pending</span>
                                        </div>}
                                        </Link>
                                </td>
                                <td className="whitespace-nowrap text-slate-700 font-semibold border-b-1 border-slate-200 first:rounded-l-md last:rounded-r-md bg-slate-200 space-y-2.5 shadow-[20px_3px_20px_#0000000b]">
                                        <div className="flex items-center justify-center"> 
                                        <Link to={"/createBillet/"+tasklisted.tasktype+"/"+tasklisted.taskid+"/"+tasklisted.workstation_id+"/"+tasklisted.operator_user_id+"/"+tasklisted.temp_taskid} 
                                state={{module_id:tasklisted.id, work_name:tasklisted.workstation_name, loss_per:tasklisted.workstation_loss, job_id:tasklisted.job_id}} className="flex justify-center items-center w-full text-center text-slate-500" title="Edit">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                                                    <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clipRule="evenodd" />
                                                </svg>
                                            </Link>
                                        </div>
                                </td>
                            
                                </tr>
                            ))}  
                            </tbody>
                        ) : (
                            <tbody >
                                <tr >
                                    <td colSpan="4">
                                    <p className="flex items-center justify-center text-xl text-center pt-[82px]">No Records Available</p>
                                    </td>
                                </tr> 
                            </tbody>
                        )} 
                </table>
                </div>
    
    </>
    
   )
}