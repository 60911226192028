import { Routes, Route, useLocation } from "react-router-dom";
import { useState } from 'react';
import {Login} from "../pages/user/Login";
import {Header} from "../components/Header";
import {TaskList} from "../pages/tasklist/Index";
import {CreateBillet} from "../pages/taskedit/Index";

export const Router = () => {  
  const [isActive] = useState(false);
  let location = useLocation();
  const token = localStorage.getItem('accessToken');

  if(!token) {
    return <Login />
  }
  
     return(
        <>
         {location.pathname !== '/' && <Header isActive={isActive} />}
         <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/task" element={<TaskList /> } />
            <Route  path="/createBillet/:tasktype/:taskid/:wrkstid/:operatorid/:temptaskid" element={<CreateBillet />} />
          </Routes>
        </>
    )
}