import {React, Fragment, useRef, useState, useEffect} from "react";
import { Dialog, Transition } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import swal from "sweetalert";
import { RevolvingDot } from 'react-loader-spinner';

export const UpdatePallets = ({handelPallets, closePallets, palletParamDetails}) =>{
console.log('palletParamDetails', palletParamDetails);
    const [palletParams, setPalletParams] = useState(palletParamDetails);
    const [loading, setLoading] = useState(false);
   // const [palletParams, setPalletParams] = useState(palletParamDetails);
    const [records, setRecords] = useState([]);
    const [recordItems, setRecordItems] = useState([]);
    
    const [palletHeader, setPalletHeader] = useState([]);
    const [palletCount, setPalletCount] = useState(0);
    const [tempPallet, setTempPallet] = useState([]);
    const [tempQtY, setTempQty] = useState();
    //console.log('totalWeightApplied', totalWeightApplied);
    console.log(palletParams);
    const getpalletdetail = () => {

        console.log('palletParams', palletParams);
        setLoading(true);
        const additionalUrl =  "production/jobnew/packagetask/palletdetails/" + palletParamDetails.process_id + "/" + palletParamDetails.job_id;
       // const additionalUrl =  "production/jobnew/packagetask/palletdetails/289/172"; //test
        console.log(additionalUrl);
        axios.get(`${additionalUrl}`).then((response) => {
            //setLoading(false);
            console.log('data', response.data.data);
            if(response.data.data){
                const rec = response.data.data;
                //console.log('rec', rec);
                setRecords(rec.pallets);
                setPalletCount(rec.pallets.length);
                setRecordItems(rec.packagedetails);
                var pallethead = rec.packagedetails;
                //setPalletHeader(pallethead.toReversed());
                setPalletHeader(pallethead);
                console.log('header', pallethead);
                var array = [];
                rec.pallets.forEach((element) => {



                    element["job_id"] = rec.packagedetails[0]['job_id'];
                    element["order_id"] = rec.packagedetails[0]['order_id'];
                    
                    var itemArr = element["palletitems"];
                    var newitemArry= [];
                    var newpalletitemArry= [];
                    pallethead.forEach((element) => {

                      var returnedItem = itemArr.find(
                        (item) => item.lot_id == element["lot_id"] && item.orderitem_id == element["joborderitem_id"]
                      );
                      if(returnedItem && returnedItem.lot_id){
                        newpalletitemArry.push(returnedItem);
                      } else {
                        var $tempItem = [];
                        $tempItem['itemname'] = element['itemname'];
                        $tempItem['job_id'] = element['job_id'];
                        $tempItem['lot_id'] = element['lot_id'];
                        $tempItem['lot_number'] = element['lot_number'];
                        $tempItem['order_id'] = element['order_id'];
                        $tempItem['orderitem_id'] = element['joborderitem_id'];
                        $tempItem['pallet_id'] = element['pallet_id'];
                        $tempItem['pkg_id'] = element['pkg_id'];
                        $tempItem['qty'] = 0;
                        $tempItem['task_id'] = 0;
                        newpalletitemArry.push($tempItem);
                        //itemArr.push({ ...$tempItem });

                         
                       // newpalletitemArry.push(newitemArry);
                      }
                      
                     // newpalletitemArry.push(newitemArry);



                    });


                  /*  console.log('aligned itemArr', itemArr);

                    console.log('pitem', element["palletitems"]);
                    var palletitemcount = element["palletitems"].length;
                    var itemcount = pallethead.length;
                    console.log('palletitemcount',  palletitemcount);
                    console.log('itemlength',  itemcount);

                    if(itemcount > palletitemcount){
                       var $tempItem = [];
                        for(var i=palletitemcount; i<itemcount; i++){
                            console.log('i count', i);
                            if(pallethead[i]){
                                $tempItem['itemname'] = pallethead[i]['itemname'];
                                $tempItem['job_id'] = pallethead[i]['job_id'];
                                $tempItem['lot_id'] = pallethead[i]['lot_id'];
                                $tempItem['lot_number'] = pallethead[i]['lot_number'];
                                $tempItem['order_id'] = pallethead[i]['order_id'];
                                $tempItem['orderitem_id'] = pallethead[i]['joborderitem_id'];
                                $tempItem['pallet_id'] = pallethead[i][''];
                                $tempItem['pkg_id'] = pallethead[i]['pkg_id'];
                                $tempItem['qty'] = 0;
                                $tempItem['task_id'] = 0;
                                itemArr.push({ ...$tempItem });
                            }
                        }

                    }*/

                    element["palletitems"] = newpalletitemArry;//.toReversed();

                    array.push(element);
                });
                console.log('item count',pallethead.length);

                console.log('array',array);
                setRecords(array);
                console.log('rec.packagedetails', rec.packagedetails);
                console.log('rec.pallets', rec.pallets);
                

                //console.log('records',records);
                
                setTempPallet(array);
               // console.log('empPallet',tempPallet);

              /* rec.packagedetails.forEach((packageitem, j) => {
                    console.log('J val', j);
                    var sum = 0;
                    rec.pallets.forEach(subData => sum += Number(subData.palletitems[j]? subData.palletitems[j]['qty'] : 0));
                    if (document.getElementById("netwt_"+j)) {
                        console.log("netwt_"+j, sum);
                        document.getElementById("netwt_"+j).value = sum;
                    }
                });*/

                var crosssum = 0;
                rec.pallets.forEach(subData => crosssum += Number(subData.grosswt));
                document.getElementById("grossWt").value = crosssum;
            }
        });
        
    }

    useEffect(() => {
        //Get pallet Details
        getpalletdetail();
    }, []);
 
    const cancelButtonRef = useRef(null);

    /*const onchangeWeightHandelNew = (e) => {
        
        setTempQty({
            ...tempQtY,
            [e.target.name]: e.target.value
          });
        
    }*/

   /* const onchangeWeightHandel = (parentindex, childindexs, e) => {
        console.log('onchangeWeightHandel',tempPallet);
        let regEx = /^(\d+(\.\d{0,4})?|\.?\d{1,4})$/;
        if(regEx.test(e.target.value)){
        }
        
        console.log('val', e.target.value);
        console.log("cindex", childindexs);
        console.log("pindex", parentindex);

        const childindex = childindexs;
        const changedValue = e.target.value;
        const values = [...tempPallet];
        values[parentindex].palletitems[childindex].qty = changedValue;
        
        //tempPallet[parentindex].palletitems[e.target.id].qty = e.target.value;
        //console.log("pssindex", tempPallet[parentindex].palletitems[e.target.id]);

        // const currentTodoIndex = todos.findIndex((todo) => todo.id === id);
        // // 2. Mark the todo as complete
        // const updatedTodo = {...todos[currentTodoIndex], isComplete: true};
        // // 3. Update the todo list with the updated todo
        // const newTodos = [...todos];
        // newTodos[currentTodoIndex] = updatedTodo;
        // setTodos(newTodos);

        //const currentTodoIndex = todos.findIndex((todo) => todo.id === id);
        // 2. Mark the todo as complete
        // const updatedTodo = {...tempPallet[parentindex].palletitems[e.target.id], qty: e.target.value};
        // // 3. Update the todo list with the updated todo
        // const newTodos = [...tempPallet];
        // newTodos[parentindex] = updatedTodo;
        // setTempPallet(newTodos);

        // const value = e.target.value;
        // //copying data to temp variable so that we do not directly mutate original state
        // let index = parentindex;
        // const tempWorkingHours = [...tempPallet];
        // //findIndex to find location of item we need to update
        // console.log('tempWorkingHours', tempWorkingHours);
        // // -1 check to see if we found that object in working hours
        // if(index != -1){
        //    tempWorkingHours[index].palletitems[e.target.id] = {
        //      ...tempWorkingHours[index].palletitems[e.target.id], //keeping existing values in object
        //      ["qty"]: value  //here property can be "price" or "description"
        //    }
        // }
        
        // setTempPallet({ ...tempPallet, tempWorkingHours })
    


       // const values = [...tempPallet];

        //console.log('valuesfull', values);
        //console.log('valuesparent', values[parentindex]);
        //console.log('valuesnext', values[1]);
        //setTempPallet({...tempPallet, [values[parentindex].palletitems[e.target.id].qty]: e.target.value});
        //const newItems = [...tempPallet];
        //console.log('newItems', newItems);
       // tempPallet[parentindex].palletitems[e.target.id].qty = e.target.value;
       // this.setState({ items:newItems });


        // let pitem = values[parentindex];
        // console.log('pitems', pitem);
        // pitem.palletitems[e.target.id].qty = e.target.value;
        // values[parentindex] = pitem;
        //let item = {...values[parentindex].palletitems[e.target.id]};
        //console.log('item', item);
        //item.qty = e.target.value;
        //values[parentindex].palletitems[e.target.id] = item;
        // 5. Set the state to our new copy
        //setTempPallet({values});

        //values[parentindex].palletitems[e.target.id].qty = e.target.value;
        /*
        var sum = 0;
        values.forEach(subData => sum += Number(subData.palletitems[e.target.id]['qty']));
        document.getElementById("netwt_"+e.target.id).value = sum;
        console.log('valuesafter net wt', values);

        var grosssum = 0;
        values[parentindex].palletitems.forEach(subData => grosssum += Number(subData['qty']));
        values[parentindex].grosswt = grosssum;
        document.getElementById("gross_"+parentindex).value = grosssum;

        var totalsum = 0;
        values.forEach(subData => totalsum += Number(subData.grosswt));
        document.getElementById("grossWt").value = totalsum;*/
        /*console.log('final valuessss', values);
        setTempPallet(values);
        console.log('onchangeWeightHandel end',tempPallet);
    };*/

    const onchangeGrossWt = (parentindex) => (e) => {
        let regEx = /^(\d+(\.\d{0,4})?|\.?\d{1,4})$/;
        if(regEx.test(e.target.value)){
        }
        
        console.log('val', e.target.value);
        console.log("cindex", e.target.id);
        console.log("pindex", parentindex);
        const values = [...tempPallet];
        values[parentindex].grosswt = e.target.value;
        var sum = 0;
        values.forEach(subData => sum += Number(subData.grosswt));
        document.getElementById("grossWt").value = sum;
        console.log('values', values);
        setTempPallet(values);
    };

    

    const palletChange = (event) => {
      setPalletCount(event.target.value);
    };

    const palletGo = () => {
        if(palletCount < 1 ){
            swal("Failed", 'At least one pallet count required', "error");
            return
        }
      //  var palletlength= records.length;
        var newPalletArray = [];

        for(var i=0; i<palletCount; i++){
            var palletRec = [];
            if(records[i]) {
                palletRec = records[i];
            } else {
                var itemArray = [];
                recordItems.forEach((element) => {
                    element["qty"] = 0;
                    element["pallet_id"] = i+1; 
                    element["orderitem_id"] = element["joborderitem_id"]; 
                    itemArray.push(element);
                });

                palletRec = {
                    "pallet_id": i+1,
                    "grosswt": "",
                    "job_id": recordItems[0]["job_id"],
                    "order_id": recordItems[0]["order_id"],
                    "palletitems": itemArray
                }
            }
            newPalletArray.push(palletRec);
        }
        
        console.log('array',newPalletArray);
        setTempPallet(newPalletArray);
        console.log('tempPallet',tempPallet);
        console.log('palletCount',recordItems);
        recordItems.forEach((packageitem, j) => {
            console.log('J val', j);
            var sum = 0;
            newPalletArray.forEach(subData => sum += Number(subData.palletitems[j]? subData.palletitems[j]['qty'] : 0));
            if (document.getElementById("netwt_"+j)) {
                console.log("netwt_"+j, sum);
                document.getElementById("netwt_"+j).value = sum;
            }
        });

        //palletCount
    }

    const handleSubmit = e => {
        if(palletCount < 1){
            swal("Failed", 'At least one pallet count required', "error");
            return
        }
        console.log('hidid', e);
        console.log('handleSubmit', tempPallet);
        const reqVal = tempPallet;
        //return;
        axios.post(`${"production/jobpackaging/jobpallets"}`, {pallets : reqVal, task_id :  palletParams.module_id}).then((response) => {
            console.log('data', response);
            if(response.data.success == true){
                console.log('data11', response);
                setRecords(tempPallet);
                swal("Success", response.data.message, "success", {
                    buttons: false,
                    timer: 2000
                })
            }
        }).catch(function(err){ console.log(err);swal("Failed", 'Something went wrong', "error"); });
        e.preventDefault();
    }

    //const handleAccountingChange = (newValue, name, id) => {
   /* const handleAccountingChange = (rowindex, collindex, e) => {
        // For the exemple I declare selected here
        const selected = [...tempPallet];
        const newArray = [];
        console.log(typeof selected);
        console.log(Object.entries(selected));
        const newState = selected.map((obj, pindex) => {
            //let newchildArray = {};
            var palletRec = [];
            if(pindex === rowindex) {
                var itemArray = [];
                var item = obj.palletitems;
                    item.forEach(([key,element]) => {
                        if (key === collindex) {
                            element["qty"] = e.target.value;
                            itemArray.push(element);
                        }
                    });
                // obj.palletitems.map((cobj, cindex) => {
                //     if (cindex === collindex) {
                //         cobj.qty = e.target.value;
                //         console.log('after val assig cobj', cobj);
                //     }  
                //     itemArray.push(cobj);
                // });
                // item.forEach((element) => {
                //     element["qty"] = "0";
                //     element["pallet_id"] = i+1; 
                //     element["orderitem_id"] = element["joborderitem_id"]; 
                //     itemArray.push(element);
                // });

                obj.palletitems = itemArray;
                palletRec = obj;
                // palletRec = {
                //     "pallet_id": i+1,
                //     "grosswt": "",
                //     "job_id": recordItems[0]["job_id"],
                //     "order_id": recordItems[0]["order_id"],
                //     "palletitems": itemArray
                // }
                
            } else {
                palletRec = obj;
            }
            newArray.push(palletRec);
        });
        

    console.log('newState', newArray);
        return newState; // instead ou can use setSelected(newState)
    };*/
    // const mappedFruits = fruits.map(fruit => {
    //     return {
    //         ...fruit,
    //         isFavorite: true,
    //         attributes: attributes.map(attribute => {
    //             if (cindex === collindex) {
    //                 return {
    //                     ...attribute,
    //                     isFavorite: true,
    //                 }
    //             } else 
    //             {
    //                 return {
    //                     ...attribute, 
    //                 }
    //             }
    //         })
    //     }
    // })
    const handleAccountingChange = (rowindex, collindex, e) => {
        const mappedPallets = tempPallet.map((item, pindex) => {
            if(pindex === rowindex) {
                return {
                    ...item, 
                    palletitems: item.palletitems.map((attribute, cindex) => {
                        if (cindex === collindex) {
                            return {
                                ...attribute,
                                qty: e.target.value,
                            }
                        } else 
                        {
                            return {
                                ...attribute, 
                            }
                        }

                    })
                }
            } else {
                return {
                    ...item, 
                }
            }
        })

        

        console.log('mappedPallets', mappedPallets);
        setTempPallet(mappedPallets);

        const values = mappedPallets;
        console.log('valuesll', values);
        var sum = 0;
        values.forEach(subData => sum += Number(subData.palletitems[collindex]['qty']));
        document.getElementById("netwt_"+collindex).value = sum;
        console.log('valuesafter net wt', values);

        // var grosssum = 0;
        // values[rowindex].palletitems.forEach(subData => grosssum += Number(subData['qty']));
        // values[rowindex].grosswt = grosssum;
        // document.getElementById("gross_"+rowindex).value = grosssum;

        // var totalsum = 0;
        // values.forEach(subData => totalsum += Number(subData.grosswt));
        // document.getElementById("grossWt").value = totalsum;

    }
 
    return(
        <Transition.Root show={handelPallets} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={closePallets}>
            <Transition.Child
                 as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
             >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                        <Dialog.Panel className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-2xl lg:max-w-2xl">
                                <div>
                                    
                                    <div className="flex justify-between items-center bg-gray-200 py-3 px-3">
                                        <Dialog.Title as="h3" className="text-lg font-bold">
                                          Update Pallets | <span className="text-sky-500">{recordItems[0]? recordItems[0]['jobid'] : ''}</span>
                                        </Dialog.Title>
                                        <span onClick={closePallets} ref={cancelButtonRef} className="cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </span>
                                    </div>
                                    <form className="py-5 px-4">
                                      <div className="flex items-center">
                                        <span className="text-base font-bold">Total Pallet:</span>
                                        <input type="number" value={palletCount} onFocus={(e) => e.target.value ==0 ? e.target.value="" : e.target.value}  onChange={palletChange} className="ml-2 w-28 px-2 py-4 border rounded-none border-gray-300 text-gray-900 text-lg font-samibold text-center"/>                                                        
                                        <button type="button" onClick = {palletGo}
                                        className="bg-green-600 hover:bg-green-500 text-white text-base font-medium flex items-center ml-1 px-9 py-5">
                                            <span>Go</span> 
                                        </button>
                                      </div>
                                       <div className="table-wrp block max-h-96 overflow-auto mt-4">
                                        <table className="w-full text-left border border-slate-300">
                                                <thead>
                                                    <tr>
                                                        <th colSpan="3" className="whitespace-nowrap px-4 py-3 font-bold text-slate-800 border border-slate-300">
                                                            <div className="flex text-base">
                                                                <span>Pallet Details</span> 
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="bg-slate-200 px-4 py-2 font-bold  text-slate-800 text-base  border border-slate-300">
                                                            &nbsp;
                                                        </th>
                                                        {palletHeader && palletHeader.map((item,itemindex) => (
                                                        <th className="text-center  bg-slate-200 px-4 py-3 font-bold text-slate-800 text-base  border border-slate-300">
                                                           {item.itemname +"("+ item.lot_number +")"}
                                                        </th>
                                                        ))}
                                                        <th className="text-center bg-slate-200 px-4 py-3 font-bold text-slate-800 text-base border border-slate-300">
                                                            Gross Wt
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tempPallet && tempPallet.map((item,rowindex) => (
                                                    <tr key={rowindex}>
                                                        <td className="whitespace-nowrap font-bold text-slate-700 text-lg px-4 py-2 border border-slate-200 py-2">Pallet {item.pallet_id}</td>
                                                         
                                                        {item.palletitems.map((palletitem,collindex) => (
                                                        <td key={collindex+"_"+rowindex} className="text-center font-medium text-slate-700 text-xs px-2 py-2 border border-slate-200 py-3">
                                                            <input type="number" name={"qty"+collindex+"_"+rowindex} value={palletitem.qty} 
                                                            onFocus={(e) => e.target.value ==0 ? e.target.value="" : e.target.value}
                                                            onChange={(e)=>handleAccountingChange(rowindex, collindex, e)} className="ml-2 w-28 rounded-none px-2 py-3 border border-gray-300 text-gray-900 text-base font-medium text-center"/> 


                                                          {/*<input type="number" name={"qty"+collindex+"_"+rowindex} value={palletitem.qty} onInput={(val)=>{  console.log(tempPallet[0].palletitems[0].qty=3);} } className="ml-2 w-28 rounded-none px-2 py-3 border border-gray-300 text-gray-900 text-base font-medium text-center"/>  
                                                          <input type="number" name={"qty"+collindex+"_"+rowindex} value={palletitem.qty} onChange={(e)=>onchangeWeightHandel(rowindex, collindex, e)} className="ml-2 w-28 rounded-none px-2 py-3 border border-gray-300 text-gray-900 text-base font-medium text-center"/>  
                                                         <input type="number" name={"qty"+collindex+"_"+rowindex} value={palletitem.qty} onChange={(e)=>onchangeWeightHandelNew(rowindex, collindex, e)} className="ml-2 w-28 rounded-none px-2 py-3 border border-gray-300 text-gray-900 text-base font-medium text-center"/> */} 
                                                        </td>
                                                        
                                                        ))
                                                        
                                                        }

                                                        <td className="text-center font-medium text-slate-700 text-xs px-2 py-2 border border-slate-200 py-3">
                                                           <input type="number" id={"gross_"+rowindex} defaultValue={item.grosswt} onChange={onchangeGrossWt(rowindex)} className="ml-2 w-28 rounded-none px-2 py-3 border border-gray-300 text-gray-900 text-base font-medium text-center"/>  
                                                        </td>

                                                    </tr>
                                                    ))} 
                                                    <tr>
                                                        <td className="whitespace-nowrap font-bold text-slate-700  text-base px-4 py-2 border border-slate-200 py-2">Net Wts</td>

                                                        {recordItems && recordItems.map((item,ntindex) => {
                                                              var sum=0;
                                                              console.log('tempPalletntindex', ntindex);
                                                              const netwtarray = [...tempPallet];
                                                              console.log('tempPallet', tempPallet);
                                                              tempPallet.forEach(async (item) => {
                                                                  console.log('ddd', item.palletitems);
                                                                  console.log('dddsss', item.palletitems[ntindex]['qty']);
                                                                    sum +=  Number(item.palletitems[ntindex]['qty']? item.palletitems[ntindex]['qty']: 0);
                                                                //sum = await sumFunction(sum, rating);
                                                              });

                                                              //sum = netwtarray.forEach(subData => sum +=  (subData? subData: 0));
                                                              console.log('tempPallet sum', sum);
                                                              
                                                              
                                                             // console.log('tempPalletntindexval', tempPallet[0].palletitems[ntindex]);
                                                             // console.log('nnnn', tempPallet.forEach(subData => sum += Number(subData.palletitems[ntindex]? subData.palletitems[ntindex]['qty'] : 0)));
                                                              return ( <td className="text-center font-medium text-slate-700 text-xs px-2 py-2 border border-slate-200 py-3">
                                                            <input type="number" value={sum} id={"netwt_"+ntindex} className="ml-2 w-28 rounded-none px-2 py-3 border border-gray-300 text-gray-900 text-base font-medium text-center"/>  
                                                        </td>)

                                                            })}
                                                        <td className="text-center font-medium text-slate-700 text-xs px-2 py-2 border border-slate-200 py-3">
                                                            <input type="number" defaultValue="0" id="grossWt" className="ml-2 w-28 rounded-none px-2 py-3 border border-gray-300 text-gray-900 text-base font-medium text-center"/>  
                                                        </td>
                                                    </tr>
                                                   
                                                 </tbody>
                                            </table>
                                        </div>
                                        <div className="flex justify-end mt-5">
                                       
                                                <button type="button" onClick = {handleSubmit} className="rounded-sm bg-sky-600 hover:bg-sky-500 text-white text-xl font-medium flex items-center ml-1 pl-5 pr-6 py-4">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                    </svg>
                                                    <span className="ml-1">Save</span>
                                                </button>
                                            
                                        </div>
                                    </form>
                                </div>
                                
                            </Dialog.Panel>
                      </Transition.Child>
                    </div>
                </div>
        </Dialog>
    </Transition.Root>
    )
}