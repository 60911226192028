import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import axios from "axios";



//axios.defaults.baseURL = "https://ntapi.trotterportal.com/api/";
axios.defaults.baseURL = "https://trotter.odtestlink.com/api/";

if(localStorage.getItem('accessToken')){
  const token = "Bearer " + localStorage.getItem('accessToken');
  console.log('tokentoken', token);
  axios.defaults.headers.common['Authorization'] = token;
}

axios.interceptors.response.use(response => response, error => {
  if(error.response.status === 401) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = "/";
  }
  //return error;
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
